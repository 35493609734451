import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { Dropdown, Selection } from "react-dropdown-now";
import { PencilSquare } from 'react-bootstrap-icons';
import ReactTooltip from "react-tooltip";
import { TbQuestionMark } from 'react-icons/tb';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { wtfKeys } from "./Onboarding";

const SideDrawer = (props) => {

  var randimg = "/randimg/" + (Math.floor(Math.random() * 18) + 1) + ".png";
  var quants = [
    "disabled",
    "1/8",
    "1/4",
    "1/2",
    "1"
  ];

  var bpms = [
    "60",
    "80",
    "100",
    "120",
    "140",
    "160",
    "180"
  ];

  var keys = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#"
  ];
  var scales = ["none", "major", "minor"];

  function showDrawer() {
    props.setDrawerOpen(true);
  };

  function hideDrawer() {
    props.setDrawerOpen(false);
  };

  function changeCB(event) {
    props.setAllowRepeat(event.target.checked);
    if (event.target.checked) {
      props.setWithRelease(true);
    }
  }

  function changeWithRelease(event) {
    if (!props.allowRepeat) {
      props.setWithRelease(!event.target.checked);
    }
  }

  function changeTrioBass(event) {
    props.setPlayTrioBass(event.target.checked);
  }

  function changeTrioDrums(event) {
    props.setPlayTrioDrums(event.target.checked);
  }

  function changeUseMidi(event) {
    props.setUseMidi(event.target.checked);
  }

  function changeTemperature(event) {
    props.setTemperature(event.target.value);
  }

  function changeAIOnanism(event) {
    props.setAIOnanism(event.target.checked);
  }

  function setDropDownBassInstrumentName(event) {
    console.log("instr name: " + event.value);
    // setInstrumentName(event.value);
    // props.instrument.setInstrument(instruments[event.value]);
    props.bassInstrument.setInstrumentName(event.value);
  }

  const setDrumVolumeSlider = (event) => {
    console.log("volume set: " + event.target.value);
    props.setDrumVolume(event.target.value);
  };

  return (
    <Drawer
      anchor='right'
      open={props.drawerOpen}
      onClose={hideDrawer}
      style={{}}
    >
      <CancelIcon
        onClick={() => hideDrawer()}
      />
      <center><h3 style={{ color: 'red' }}>Settings</h3></center>
      <br />
      <Row
        style={(props.wtf < wtfKeys.indexOf("KEY_SCALE")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
        >
        <center>
        <Tooltip title="Set the key all notes will be played in.">
          <span style={{ color: "blue" }}>Key and Scale</span>
          </Tooltip>
        </center>
        <Col>
          <center>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 22, width: 122 }} >
              <InputLabel id="keys-label">Key</InputLabel>
              <Tooltip placement="left-start" title="Set the key all notes will be played in.">
             <Select
                autoWidth
                labelId="keys-label"
                placeholder="Select a key"
                label='key'
                value={props.keyOf}
                onChange={(value) => {
                  props.setKeyOf(value.target.value);
                }}
              >
                {
                  keys.map((key) => {
                    return <MenuItem value={key}>{key}</MenuItem>
                  })
                }
              </Select>
              </Tooltip>
            </FormControl>
          </center>
        </Col>
        <Col>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 22, width: 122 }} >
            <InputLabel id="simple-select-standard-label">Scale</InputLabel>
            <Tooltip placement="left-start" title="Set the Scale all notes will be played in.">
            <Select
              autoWidth
              labelId="simple-select-standard-label"
              placeholder="Select a scale"
              label='Scale'
              value={props.scale}
              onChange={(value) => {
                console.log("change scale", value.target);
                props.setScale(value.target.value);
              }}
              onSelect={(value) => console.log("selected!", value)} // always fires once a selection happens even if there is no change
              onClose={(closedBySelection) =>
                console.log("closedBySelection?:", closedBySelection)
              }
              onOpen={() => console.log("open!")}
            >
              {
                scales.map((scale) => {
                  return <MenuItem value={scale}>{scale}</MenuItem>
                })
              }
            </Select>
            </Tooltip>
          </FormControl>
        </Col>
      </Row>
      {/* <hr
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : 
      {
          background: 'lime',
          color: 'lime',
          borderColor: 'lime',
          height: '3px',
        }}
      /> */}

      <Row
        style={(props.wtf < wtfKeys.indexOf("BPM_QUANT")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
        >
        <center>
          <span style={{ color: "blue" }}>Quantization</span>
        </center>
        <Col>
          <center>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 22, width: 122 }} >

              <InputLabel id="bpm-label">BPM</InputLabel>
              <Select
                autoWidth
                labelId="bpm-label"
                placeholder="Select a bpm"
                label='bpm'
                value={props.bpm}
                onChange={(value) => {
                  props.setBpm(value.target.value);
                }}
              >
                {
                  bpms.map((key) => {
                    return <MenuItem value={key}>{key}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </center>

        </Col>
        <Col>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 22, width: 122 }} >
            <InputLabel id="quant-label">Quantization</InputLabel>
            <Select
              autoWidth
              labelId="quant-label"
              placeholder="Select a Quantizatio"
              value={props.quant}
              onChange={(value) => {
                console.log(props.quant);
                console.log("change!", value);
                props.setQuant(value.target.value);
              }}
            >
              {
                quants.map((key) => {
                  return <MenuItem value={key}>{key}</MenuItem>
                })
              }
            </Select>

          </FormControl>
        </Col>
      </Row>

      {/* </FormControl> */}

      {/* <hr
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : 
      {
          background: 'lime',
          color: 'lime',
          borderColor: 'lime',
          height: '3px',
        }}
      /> */}

      {/* <div  */}
      {/* style={(props.wtf < 9) ? {display:'none'} : {padding: "0px"}}> */}
      {/* <div style={(!props.withRelease) ? {display:'none'} : {padding: "0px"}}>
        <label>
        <input
          type="checkbox"
          checked={props.allowRepeat}
          onChange={changeCB}
        />
        &nbsp; Allow Keyboard Press Repeat
        <TbQuestionMark data-tip='Hold down a key and the instrument with receive tons of play commands.' aria-haspopup="true" role="example"/><ReactTooltip/>
      </label>
      </div> */}
      <div style={(props.allowRepeat) ? { display: 'none' } : { padding: "0px" }}>
        <label>
          <input
            type="checkbox"
            checked={!props.withRelease}
            onChange={changeWithRelease}
          />
          &nbsp; key depress stops sound
          <TbQuestionMark data-tip='Overwrites the duration of the instrument note and stops when you depress the key or button.  Only noticeable on instruments with long notes such as the bassoon' aria-haspopup="true" role="example" /><ReactTooltip />
        </label>
      </div>
      {/* <label>
        <input
          type="checkbox"
          checked={props.playTrioDrums}
          onChange={changeTrioDrums}
        />
        &nbsp; AI drums accompaniement 
      </label>
      <TbQuestionMark data-tip='Once you start playing for a bit, the AI will maybe add what it thinks makes sense.' aria-haspopup="true" role="example"/><ReactTooltip/>
      | Drum volume:{props.drumVolume}&nbsp; 
      <input
              value={props.mp3Vol}
              type="range"
              onChange={setDrumVolumeSlider}
              min="0"
              max="100"
              step="1"
      ></input>
      &nbsp;| 
      <label>
        <input
          type="checkbox"
          checked={props.playTrioBass}
          onChange={changeTrioBass}
        />
        &nbsp; AI Bass Accompaniement
      </label>
      <TbQuestionMark data-tip='Once you start playing for a bit, the AI will maybe add what it thinks makes sense.' aria-haspopup="true" role="example"/><ReactTooltip/>
      <div class="col-auto">
      <Dropdown
            placeholder="Bass"
            // className="my-className"
            options={props.instrumentOptions}
            value={props.bassInstrument.instrumentName}
            onChange={(value) => {
                console.log("change!", value);
                setDropDownBassInstrumentName(value);
            }}
        />
        <a href="#"><PencilSquare onClick={() => props.editBass()}></PencilSquare></a>
      </div> */}
      {/* <label>
        <input
          type="checkbox"
          checked={props.AIOnanism}
          onChange={changeAIOnanism}
        />
        &nbsp; AI Doesn't wait for me to play
      </label> */}
      {/* <TbQuestionMark data-tip='Once you start playing for a bit, the AI will maybe add what it thinks makes sense.' aria-haspopup="true" role="example"/><ReactTooltip/>
      <label>
        &nbsp; drums'n bass temperature:
        <TbQuestionMark data-tip='The greater the temperature, the less predictable the notes played.' aria-haspopup="true" role="example"/><ReactTooltip/>
        <input
          type="text"
          value={props.temperature}
          onChange={changeTemperature}
        />
      </label> */}
      <center
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
      >
      AI Drum volume:&nbsp;&nbsp;{props.drumVolume}&nbsp;
        <input

          value={props.mp3Vol}
          type="range"
          onChange={setDrumVolumeSlider}
          min="0"
          max="100"
          step="1"
        ></input>
      </center>
      {/* <div class="col-auto">
      <Dropdown
            placeholder="Bass"
            // className="my-className"
            options={props.instrumentOptions}
            value={props.bassInstrument.instrumentName}
            onChange={(value) => {
                console.log("change!", value);
                setDropDownBassInstrumentName(value);
            }}
        />
        <a href="#"><PencilSquare onClick={() => props.editBass()}></PencilSquare></a>
      </div> */}


      <hr
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : 
          {background: 'lime',
          color: 'lime',
          borderColor: 'lime',
          height: '3px',
        }}
      />
      <center
          style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
        >
        <FormControlLabel
          control={
            <Switch
              checked={props.useMidi}
              onChange={changeUseMidi}
            />}
          label="Use Midi"
        />
        {/* <TbQuestionMark data-tip='Sends midi data instead of playing audio in the web page.' aria-haspopup="true" role="example"/><ReactTooltip/> */}
      </center>
      <hr
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : 
      {
          background: 'lime',
          color: 'lime',
          borderColor: 'lime',
          height: '3px',
        }}
      />
      <br />



      <div style={{ marginTop: `auto` }}>
        <Card
          style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
        > 
            <Card.Header
            style={{ backgroundColor: 'black', color: 'white' }}
          >Dev Zone -- Please ignore</Card.Header>
          <Card.Body>
            {/* <label>
        <input
          type="checkbox"
          checked={props.showLog}
          onChange={props.changeLog}
        />
        &nbsp; Show Logs
      </label> */}
            <label>
              <input
                type="checkbox"
                checked={props.connectMQTT}
                onChange={props.changeMQTT}
              />
              &nbsp; Connect MQTT
            </label>

            <Button
              style={(props.wtf < 9) ? { display: 'none' } : { marginLeft: "11px" }}
              onClick={() => props.logPreset()}>
              Show json of current setup
            </Button>

          </Card.Body>
        </Card>
        <center><img src={randimg} width='200' /></center>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
