import React, { useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { Dropdown, Selection } from "react-dropdown-now";
import Button from '@mui/material/Button';
import { Stack } from "@mui/system";

const AboutModal = (props) => {

  return (
    <Modal
      fullscreen={true}
      {...props}
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: '2222' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <center>Ludwig van A.I.</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid"
      style={{padding:'33px'}}>
        <Container>
          <Row>
            <br/>
            <br/>
            <Col>
            <center>
              <h4>Guten Tag!</h4>
              <br />
              <p>I hope you enjoy this app.  I'd love to hear your <b>feedback</b> and any <b>suggestions</b>: <a href='mailto:lemuel@pixelflow.art' target="_blank">tell me!</a></p>
              <br />
              <h4>Credits:</h4>
              <ul>
                <li>Images: A.I.</li>
                <li>Music: A.I.</li>
                <li>Video Music: A.I.</li>
                <li>Video Art: A.I.</li>
                <li>Story: A.I.</li>
                <li>LipSynch: A.I.</li>
                <li>Game Music AI: <a href='https://magenta.tensorflow.org/pianogenie'>Google's Magenta</a></li>
                <li>Patching different AIs together: <a href='mailto:lemuel@pixelflow.art' target="_blank">Lemuel</a></li>

              </ul>
              <br />
              <Button
                onClick={() => {
                  window.open('https://buymeacoffee.com/lemuel', '_blank');
                }}
                style={{
                  // fontFamily: 'Orbitron',
                  // fontSize: 'small',
                  //  position: 'absolute', bottom: 33, right: 33,
                  backgroundColor: 'black', color: 'white',
                  paddingLeft: '11px', paddingRight: '11px'
                }}
              >
                Donate to help me add features
              </Button>
              <br/>
              <br/>
              </center>
            </Col>
            <Col>
              <center><img src={"/ludwig.gif"} width='300' /></center>
            </Col>
          </Row>
          <br />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AboutModal;
