import React, { useRef, useState, useEffect } from "react";
import {Card, ButtonGroup, Button, Modal, Container, Row, Col, ModalBody } from "react-bootstrap";
import { Next } from "react-bootstrap/esm/PageItem";
import Recording from "./Recording";
import SettingsIcon from '@mui/icons-material/Settings';


  export const wtfKeys = ["ONE_BUTTON", "UP_DOWN", "EDIT_INSTRUMENT", "TWO_INSTRUMENTS", "KEY_SCALE", "BACKGROUND_MUSIC", 
    "ALL_INSTRUMENTS_AND_SOUNDS", "BPM_QUANT","RECORD_YOURSELF", "AI_DRUMS", "PRESETS"];


const Onboarding = (props) => {

  var displayWTF = [];
  displayWTF["ONE_BUTTON"] = {
      title:"One Button", 
      description: "Here's a button.  Hit it.  And again.  Change up the pattern.  See what notes get played.<br/>  The robot chose those.  You're making music together.<br/>  You Can use the letter A if you're on a computer.",
      ids: ['0reg']};
  displayWTF["UP_DOWN"] = {title:"Up Down", description: "The AI Robot wants you to have greater choice.  <br/>Go up or down in pitch by hitting those buttons.<br/> Use the Q and Z keys.",
      ids: ['0high','0low']};
  displayWTF["EDIT_INSTRUMENT"] = {title:"Change The Instrument", description: "Hopefully you aren't bored yet, but if you're tired of the piano, pick something else.  Use the drop down.<br/>  You can also edit the instrument so that only very high notes are played.  <br/>Or the notes last longer.  <br/>Or change the volume.  <br/>Use the settings icon to edit the instrument.",
      ids: ["0edit_instrument"]};
  displayWTF["TWO_INSTRUMENTS"] = {title:"Two Instruments", description: "Cool, now with a second instrument.  <br/>Have at it!<br/> Edit them to try different combinations of volume, note duration, pitch, etc..",
      ids: ['second_instrument']};
  displayWTF["KEY_SCALE"] = {title:"Key and Scale", description: "Set the key and scale.  <br/>A bit of music theory.  If that doesn't mean anything to you, play with them and see if you can hear the difference.<br/><a href='https://ledgernote.com/blog/interesting/musical-key-characteristics-emotions/'>This</a> says C Major is the key of children's songs.  F Minor is described as Obscure, Plaintive, Funereal.<br/>On the piano at the top of the page, the keys that aren't in the key and scale will get grayed out.",
      ids: ["key_scale"]};
  displayWTF["BACKGROUND_MUSIC"] = {title:"Background music/lyrics", description: "How about some background music or lyrics you can jam with?<br/>Select a track from the dropdown.  Or if there's something you want to jam with, play it in another tab.<br/> It might sound better in a specifc key.  Experiment.",
      ids: ['background_music']};
  displayWTF["ALL_INSTRUMENTS_AND_SOUNDS"] = {title:"Another Instrument", description: "Whoosh, here's another instrument.  <br/>And Some sounds.<br/>Click on them when you want one, or let it play on its own at random intervals.<br/>",
      ids: ['instrument2', 'sounds']};
  displayWTF["BPM_QUANT"] = {title:"BPM and Quantization", description: "Your timing doesn't have to be perfect. <br/> <br/>The system can play your notes only on the quarter measure, half measure, etc.  It might sound better (hint: it probably will).  <br/>Try it with 80 BPM and 1/2 note and fumble at the keys.<br/>See what happens and increase the frequency.",
      ids: ['bpm_quant']};
  displayWTF["RECORD_YOURSELF"] = {title:"Record Yourself", description: "If you want to record what you create and play it back, you can do so.  <br/>It will only record the instruments.",
      ids: ['record_yourself']};
  displayWTF["AI_DRUMS"] = {title:"AI Drums", description: "Want some AI drums to accompany?<br/>  Once you turn it on, start playing.  <br/>The bots will join in after a few seconds.",
      ids: ["ai_drums"]};
  displayWTF["PRESETS"] = {title:"Presets", description: "There are a lot of options.  <br/>Some presets have been created.<br/>  You can explore what's there.  <br/><br/>Now go play!",
      ids: ['presets']};
  
  
  useEffect(() => {
    // rm classes TODO
    for(var i = 0; i < wtfKeys.length; i++){
        displayWTF[wtfKeys[i]].ids.forEach(id => {
            var r = document.getElementById(id);
            r.classList.remove('wtf');
            console.log('removed class from  ' + id);
        })
    }

    if (props.wtf <= wtfKeys.length){
        // add class current
        console.log('update ' + displayWTF[wtfKeys[props.wtf]]);
        displayWTF[wtfKeys[props.wtf]].ids.forEach(id => {
            var r = document.getElementById(id);
            r.classList.add('wtf');
            console.log('added class to ' + id);
        })
    }
  }, [props.wtf]);
  
  function next(){
    console.log("wtf: " + props.wtf);
    if (props.wtf < 22) {
      props.setWTF(props.wtf + 1);
    }
  }
  function prev(){
    if (props.wtf >0 ) {
      props.setWTF(props.wtf - 1);    
    }
  }
  function done(){
    props.setWTF(22);    
  }

    if (props.wtf < 20) {
    return (
        <Col>
      <Card style={{padding: "0px", paddingTop:"11px"}}>
        <Card.Header
          style={{backgroundColor: 'black', color: 'white'}}
          ><strong dangerouslySetInnerHTML={{__html: displayWTF[wtfKeys[props.wtf]].title}} /></Card.Header>
        <Card.Body>
            <ButtonGroup>
            {props.wtf < (wtfKeys.length - 1) ?  
            <Button 
              style={{margin: '2px'}}
              onClick={() => next()}
            >Next</Button>
            : null }
            {props.wtf > 0 ?
              <Button 
              style={{margin: '2px'}}
              onClick={() => prev()}
            >Prev</Button>:null}
            <Button 
              style={{margin: '2px'}}
              onClick={() => done()}
            >Done</Button>
            </ButtonGroup>
            <br/>
            <br/>
          { <p dangerouslySetInnerHTML={{__html: displayWTF[wtfKeys[props.wtf]].description}} /> }
        </Card.Body>
    </Card>        

  
    </Col>
    );
    } else {
        return null;
    }

};

export default Onboarding;
