import InputLabel from '@mui/material/InputLabel';
import { Dropdown, Selection } from "react-dropdown-now";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import Button from '@mui/material/Button';
import { Stack } from "@mui/system";
import Piano from "./Piano";
import KeyOf from "./KeyOf";
import { Play } from "react-bootstrap-icons";
import Chords from './Chords';


const PianoKeyScaleModal = (props) => {
  var keys = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#"
  ];
  var scales = ["none", "major", "minor"];

  function robot0() {
    props.playAsynch(0, -1);
  }
  function robot1() {
    props.playAsynch(0, 1);
  }
  function ludwig() {
    props.playAsynch(0, 0);
  }

  // const { show, ...props } = pksprops;

  return (
    <div>
      <Piano
        prefix={'pks'}
        {...props}
      />

      {/* <Chords
        {...props}
      /> */}
      <div style={{ float: 'left' }}>
        {props.lastPlayedAny && 
          <p style={{ fontSize: 'x-large' }}>Note: <strong>{props.lastPlayedAny}</strong></p>
        }
      </div>
      <br />
      <center style={{ fontWeight:'bold', display: 'flex', justifyContent: 'center' }}>
        Key: <Dropdown
          options={keys}
          value={props.keyOf}
          onChange={(value) => {
            console.log("change!", value);
            props.setKeyOf(value.value);
          }}
        />

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scale: <Dropdown
          options={scales}
          value={props.scale}
          onChange={(value) => {
            console.log("change!", value);
            props.setScale(value.value);
          }}
        />
      </center>
      <br />
      <center>
        {/* <KeyOf
                        {...props}
                    /> */}
        <br />
        <img onClick={robot0} src={"/images/leftrobotglowblack.png"} height='252' style={{border:"red solid 5px", "pointerEvents": "all" }} />
        &nbsp;&nbsp;&nbsp;
        <img onClick={ludwig} src={"/images/middlerobotglowblack.png"} height='222' style={{border:"red solid 5px", "pointerEvents": "all" }} />
        &nbsp;&nbsp;&nbsp;
        <img onClick={robot1} src={"/images/rightrobotglowblack.png"} height='192' style={{ border:"red solid 5px", "pointerEvents": "all" }} />
        <br />
        <p style={{fontWeight:'bold', fontSize: 'x-large' }}>
        Click on us to make music -- lower or higher pitches. <br /> See which keys light up depending on they key and scale selected.<br /><br />
          Can you hear the difference?
          </p>
<br/>
        <p style={{fontWeight:'bold', fontSize: 'x-large' }}>
          <a href='https://ledgernote.com/blog/interesting/musical-key-characteristics-emotions/'>This</a> says C Major is the key of children's songs.  F Minor is described as Obscure, Plaintive, Funereal.
        </p>
        <br />
        <br />
        {/* <Button onClick={props.onHide()}>Cool, got it</Button> */}

      </center>
    </div>
  );
};

export default PianoKeyScaleModal;
