import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";

Sentry.init({
  dsn: "https://5fd9d4d596f949619610a1830d2050ec@o4505036893913088.ingest.sentry.io/4505036896141312",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      // maskAllText: true,
      // blockAllMedia: true,
    }),
  ],
    // integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
    <App />
  // </StrictMode>
);
