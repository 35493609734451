// import React, { useState } from "react";
import * as Tone from "tone";
import {Loader} from "./Load";
import './config';

class Instrument {
  static getInstrument(instrumentName) {
    return Loader.getInstrument(instrumentName);
  }

  letterNotes = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#"
  ];

  constructor(
    instrumentName,
    volume = 0,
    noteDuration = 4,
    whiteKeysMin = 22,
    whiteKeysMax = 87,
    key = "C",
    scale = "major",
    temperature = 0.25,
    playChords = false
  ) {
    this.instrumentName = instrumentName;
    this.instrument = Instrument.getInstrument(instrumentName);
    if (this.instrument) {
      this.instrument.toDestination();
    }
    this.noteDuration = noteDuration;
    this.whiteKeysMin = whiteKeysMin;
    this.whiteKeysMax = whiteKeysMax;
    this.playChords = playChords;
    this.key = key;
    this.keyIndex = this.letterNotes.indexOf(key);
    this.scale = scale;
    this.volume = volume;
    // this.lastPlayed = lastPlayed;z
    // this.keyWhitelistArr = [];
    this.updateWhiteKey();
    this.playing = [];
    this.temperature = temperature;
  }

  static constructFromJson(jsonString) {
    let obj = JSON.parse(jsonString);
    return this.constructFromObject(obj);
  }

  static constructFromObject(obj) {
    return new Instrument(
      obj.instrumentName,
      obj.volume,
      obj.noteDuration,
      obj.whiteKeysMin,
      obj.whiteKeysMax,
      obj.key,
      obj.scale
    );
  }

  toJson() {
    let j = {};
    j["instrumentName"] = this.instrumentName;
    j["volume"] = this.volume;
    j["noteDuration"] = this.noteDuration;
    j["whiteKeysMin"] = this.whiteKeysMin;
    j["whiteKeysMax"] = this.whiteKeysMax;
    j["key"] = this.key;
    j["scale"] = this.scale;
    return JSON.stringify(j);
  }

  setKey(key) {
    this.keyIndex = this.letterNotes.indexOf(key);
    console.log("keyindex: " + this.keyIndex);
    this.updateWhiteKey();
  }
  setScale(scale) {
    this.scale = scale;
    this.updateWhiteKey();
  }
  setWhiteKeyMin(wkMin) {
    this.whiteKeysMin = wkMin;
    this.updateWhiteKey();
  }
  setWhiteKeyMax(wkMax) {
    this.whiteKeysMax = wkMax;
    this.updateWhiteKey();
  }

  setVolume(volume) {
    this.volume = volume;
    this.instrument.volume.value = volume;
  }

  setPlayChords(playChords) {
    this.playChords = playChords;
  }

  setNoteDuration(noteDuration) {
    this.noteDuration = noteDuration;
  }

  scalesCalc = {
    major: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
    minor: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0],
    none: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  };

  updateWhiteKey() {
    this.keyWhitelistArr = [];
    for (var i = this.whiteKeysMin; i <= this.whiteKeysMax; i++) {
      if (this.scalesCalc[this.scale][(i - this.keyIndex) % 12] == 1) {
        this.keyWhitelistArr.push(i);
      }
    }
//    console.log(this.keyWhitelistArr);
  }

  setTemperature(temperature) {
    this.temperature = parseFloat(temperature)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ;
  }

  setInstrumentName(instrumentName) {
    this.instrumentName = instrumentName;
    this.instrument = Instrument.getInstrument(instrumentName);
    this.instrument.toDestination();
  }

  playSequence(sequence) {
    sequence.forEach(note => {
        var pitch = Tone.Frequency(note.pitch -21, "midi").toNote();;
        var start = note.quantizedStartStep / 8 * 1000;
        console.log("playing sequence pitch: " + pitch + " start: " + start);
        setTimeout(() => this.play(pitch), start); 
        // this.play(pitch, start);
    });
  }

  playGenie(pitch = 0, withRelease = true) {
    var num = this.getRandomNum(pitch);
    console.log("play geni rand " + num);
    return this.playGeniePitch8(pitch, withRelease);
  }

  getRandomNum(pitch = 0) {
    var num;
    if (pitch == 1) {
      var num = 7 - Math.floor(Math.random() * 2);
    } else if (pitch == -1) {
      var num = Math.floor(Math.random() * 2);
    } else {
      var num = Math.floor(Math.random() * 6) + 1;
    //   var num = Math.floor(Math.random() * 8);
    }
    return num;
  }

  async playGenieAsync(pitch, withRelease = true, quant = .25, bpm = 120) {
    //120 => 2 b/s
    // quant 1/4 => 1/8s
    // quant / (bpm /60 )

    // var num = this.getRandomNum(pitch);
    // console.log("pitch: " + pitch + " -- play async pitch from num " + num);

    var quantDuration = 1000 * quant / (bpm / 60);
    var time = Date.now();
    console.log("time % quantDuration: " + (time % quantDuration));
    console.log("(quantDuration/20): " + (quantDuration / 20));
    // exact:
    var q = quantDuration - (time % quantDuration);
    console.log('quantization wait ms:' + q );
    await new Promise(resolve => setTimeout(resolve, q));

    // if (time % quantDuration > (quantDuration * .2 ) && time % quantDuration < (quantDuration * .80)) {
    //   var q = (quantDuration - (time % quantDuration ) - Math.random() * 20 / 100 * quantDuration);
    //   console.log('quantization wait ms:' + q );
    //   await new Promise(resolve => setTimeout(resolve, q));
    // }
    console.log("playing note at " + new Date(Date.now()).toTimeString());
    // var note1 = genie1.nextFromKeyWhitelist(num, this.keyWhitelistArr, 0.25);
    // console.log(note1);
    // var note1midi = note1 + 21;
    // var letnum = Tone.Frequency(note1midi, "midi").toNote();
    // if (withRelease) {
    //   this.play(letnum);
    // } else {
    //   this.playAttack(letnum, pitch);
    // }
    var letnum = this.playGeniePitch8(pitch, withRelease);
    return letnum;
  }

  playGeniePitch8(pitch, withRelease = true) {
    var num = this.getRandomNum(pitch);
    console.log("play pitch from num " + num);
    var note1 = genie1.nextFromKeyWhitelist(num, this.keyWhitelistArr, this.temperature);
    var note1midi = note1 + 21;
    var letnum = Tone.Frequency(note1midi, "midi").toNote();
    console.log(note1);
    if (withRelease) {
      this.play(letnum);
    } else {
      this.playAttack(letnum, pitch);
    }
    return letnum;
  }

  // add note to genie 
  playGenieForceNote(note) {
    var whiteKeyNote = Tone.Frequency(note).toMidi() - 21;
    if (whiteKeyNote < 44) {
    var whiteKeyList = [whiteKeyNote, 87];
    } else {
      var whiteKeyList = [whiteKeyNote, 0];
    }
    var note1 = genie1.nextFromKeyWhitelist(0, whiteKeyList, this.temperature);
    if (note1 != whiteKeyNote) {
      console.log("FAILED TO PLAY whitekeynote desired !! ugh");
    }
    this.play(note);
    return note;
  }

  release(pitch) {
    console.log('release: ' + pitch);
    console.log(this.playing);
    var releaseTime = "+0.25";
    // var releaseTime = Tone.now();
    // this.instrument.triggerRelease(this.playing[pitch], releaseTime); //, '@2n');
    if (this.instrument.name == "Sampler") {
      // this.instrument.triggerRelease(this.playing[pitch], releaseTime);
      this.instrument.triggerRelease(this.playing[pitch], releaseTime);
    } else {
      this.instrument.triggerRelease(
        
      );
    }
    this.playing[pitch] = null;
  }

  getChordNotes(note){
    
  }

  play(note) {
    if (global.config.midi) {
      return;
    }
    console.log("playing tone: " + note.toString());
    if (!this.instrument) {
      console.log('STILL LOADING');
      return;
    }
    if (this.playChords) {
      var notes = getChordNotes(note);
    }
    // var noteToPlay = Tone.Frequency(note, "midi").toNote();
    // var velocity = 0.5 + 0.5 * Math.random();
    this.instrument.triggerAttackRelease(note, this.noteDuration);//, '@0n', 1); //velocity); //, '@2n');
  }

  playAttack(note, pitch) {
    if (global.config.midi) {
      return;
    }
    console.log("playing tone: " + note.toString());
    if (!this.instrument) {
      console.log('STILL LOADING');
      return;
    }
    // var noteToPlay = Tone.Frequency(note, "midi").toNote();
    this.instrument.triggerAttack(note); //, '@2n');
    this.playing[pitch] = note;
  }
}

export default Instrument;
