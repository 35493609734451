import React, { useState } from "react";
import { ButtonGroup, Button, Modal, Container, Row, Col } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { Dropdown, Selection } from "react-dropdown-now";
import ReactTooltip from "react-tooltip";
import { TbQuestionMark} from 'react-icons/tb';

import "react-dropdown-now/style.css";
const InstrumentModal = (props) => {
  const [minValue, set_minValue] = useState(11);
  const [maxValue, set_maxValue] = useState(22);
  const [volume, setVolume] = useState(0);
  const [noteDuration, setNoteDuration] = useState(4);
  const [instrumentName, setInstrumentName] = useState("");
  const [temperature, setTemperature] = useState(0.25);

  const handleInputWhiteKey = (e) => {
    console.log("RANGE");
    console.log(e);
    console.log(props.instrument);
    if (minValue != e.minValue){
      var note1midi = e.minValue + 21;
    } else {
      var note1midi = e.maxValue + 21;
    }
    var letnum = Tone.Frequency(note1midi, "midi").toNote();
    props.instrument.play(letnum)
    // props.setinstrumentfrommodal(props.instrument);
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    props.instrument.setWhiteKeyMin(e.minValue);
    props.instrument.setWhiteKeyMax(e.maxValue);
  };

  const enter = () => {
    set_minValue(props.instrument.whiteKeysMin);
    set_maxValue(props.instrument.whiteKeysMax);
    setVolume(props.instrument.volume);
    setNoteDuration(props.instrument.noteDuration);
    setInstrumentName(props.instrument.instrumentName);
  };

  const setVolumeSlider = (event) => {
    console.log("volume set: " + event.target.value);
    setVolume(event.target.value);
    props.instrument.setVolume(event.target.value);
  };

  const setTemperatureSlider = (event) => {
    console.log("temp set: " + event.target.value);
    setTemperature(event.target.value);
    props.instrument.setTemperature(event.target.value);
  };

  const setnoteDurationSlider = (event) => {
    console.log("note duration: " + event.target.value);
    setNoteDuration(event.target.value);
    props.instrument.setNoteDuration(event.target.value);
  };

  const setDropDownInstrumentName = (event) => {
    console.log("instr name: " + event.value);
    setInstrumentName(event.value);
    // props.instrument.setInstrument(instruments[event.value]);
    props.instrument.setInstrumentName(event.value);
  };

  const playG = (num) => {
    // function playG(num) {
    // return function (num) {
      console.log(num);
      props.instrument.playGeniePitch8(num);
    // };
  }

  return (
    <Modal
      onEntering={() => enter()}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: '222222'}}
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Instrument
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Dropdown
              placeholder="Select an option"
              className="my-className"
              options={props.instruments}
              value={instrumentName}
              onChange={(value) => {
                console.log("change!", value);
                setDropDownInstrumentName(value);

                // props.setInstrumentFromModal(props.instrument);
              }}
              onSelect={(value) => console.log("selected!", value)} // always fires once a selection happens even if there is no change
              onClose={(closedBySelection) =>
                console.log("closedBySelection?:", closedBySelection)
              }
              onOpen={() => console.log("open!")}
            />
          </Row>
          <br/>
          <Row>
            Volume: {volume}
            <input
              value={volume}
              type="range"
              onChange={setVolumeSlider}
              min="-22"
              max="11"
              step="0.5"
            ></input>
          </Row>
          <br/>
          <Row>
            Note duration: {noteDuration}s
            <input
              value={noteDuration}
              type="range"
              onChange={setnoteDurationSlider}
              min="0"
              max="11"
              step="0.25"
            ></input>
          </Row>
          <br/>
          <Row>
          <div className="col-auto">
           Note Range:<TbQuestionMark data-tip='Plays only notes within the note range' aria-haspopup="true" role="example"/><ReactTooltip/>
           </div>
          {Tone.Frequency(parseInt(minValue) + 21, "midi").toNote()} to {Tone.Frequency(parseInt(maxValue) + 21, "midi").toNote()}
            <MultiRangeSlider
              min={0}
              max={87}
              step={5}
              ruler={false}
              label={false}
              preventWheel={false}
              minValue={minValue}
              maxValue={maxValue}
              onInput={(e) => {
                handleInputWhiteKey(e);
              }}
            />
          </Row>
          <br/>
          <Row>
          <div class="col-auto">
            Temperature:<TbQuestionMark data-tip='The greater the temperature, the less predictable the next note played' aria-haspopup="true" role="example"/><ReactTooltip/>
             {props.instrument.temperature}
            </div>
            <input
              value={props.instrument.temperature}
              type="range"
              onChange={setTemperatureSlider}
              min="0"
              max="1"
              step="0.05"
            ></input>
          </Row>
          <br/>
          <Row>
          Try me:
          <ButtonGroup className="flex">
            <Button 
              style={{ color:'white', backgroundColor:'#393232',margin:'2px',fontSize: "1.5rem" }}
              onClick={() => {playG(-1)}}>
              Low
            </Button>
            <Button               
              style={{ color:'black', backgroundColor:'gray',margin:'2px',fontSize: "1.5rem" }}
              onClick={() => {playG(0)}}>{instrumentName}</Button>
            <Button 
              style={{ color:'black', backgroundColor:'lightgray',margin:'2px',fontSize: "1.5rem" }}
              onClick={() => {playG(1)}}>
              High
            </Button>
          </ButtonGroup>
          </Row>
         </Container>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={props.onSave}>Save</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

// const [modalShow, setModalShow] = useState(false);
export default InstrumentModal;
