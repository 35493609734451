import Instrument from "./Instrument";

var presetArray = {};
var gamePresetArray ={};

var chilloutDubPresetJson = `{
  "name": "Chill out Dub",
  "visible": true,
  "loop": true,
  "presetTime": 460,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "120", 
  "mp3":"chilloutdubinE.mp3", 
  "mp3Vol": "-4",
  "scale":"minor",
  "key":"E",
  "playTrioBass": false,
  "playTrioDrums": false, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": false, 
  "instruments":[{"instrumentName":"bass-electric","volume":"0","noteDuration":"4","whiteKeysMin":10,"whiteKeysMax":60,"key":"E","scale":"minor"},{"instrumentName":"guitar-electric","volume":"0","noteDuration":"4","whiteKeysMin":9,"whiteKeysMax":60,"key":"D","scale":"minor"},{"instrumentName":"synthtom","volume":"0","noteDuration":"4","whiteKeysMin":10,"whiteKeysMax":55,"key":"E","scale":"minor"},{"instrumentName":"duosynth","volume":"-5","noteDuration":"4","whiteKeysMin":"3","whiteKeysMax":"58","key":"E","scale":"minor"}],
  "instrumentTrioBass":
  {
    "instrumentName": "guitar-nylon",
    "volume": "0",
    "noteDuration": "4",
    "whiteKeysMin": "22",
    "whiteKeysMax": "75",
    "key": "C",
    "scale":  "major"
  }
}`;

var gregorianchantJson = `{
  "name": "Gregorian Chant",
  "visible": true,
  "loop": true,
  "presetTime": 222,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "80", 
  "mp3":"gregorianchant.mp3", 
  "mp3Vol": "0",
  "scale":"major",
  "key":"C",
  "playTrioBass": false,
  "playTrioDrums": false, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": false, 
  "instruments":[{"instrumentName":"organ","volume":"-8","noteDuration":"4","whiteKeysMin":10,"whiteKeysMax":45,"key":"E","scale":"minor"},{"instrumentName":"harp","volume":"5","noteDuration":"4","whiteKeysMin":14,"whiteKeysMax":55,"key":"D","scale":"minor"},{"instrumentName":"french-horn","volume":"0","noteDuration":"4","whiteKeysMin":5,"whiteKeysMax":40,"key":"E","scale":"minor"},{"instrumentName":"cello","volume":"-5","noteDuration":"8","whiteKeysMin":"3","whiteKeysMax":"58","key":"E","scale":"minor"}],
  "instrumentTrioBass":
  {
    "instrumentName": "guitar-nylon",
    "volume": "0",
    "noteDuration": "4",
    "whiteKeysMin": "22",
    "whiteKeysMax": "75",
    "key": "C",
    "scale":  "major"
  }
}`;


var leadPresetJson = `{
  "name": "Lead",
  "visible": true,
  "mp3": "lead.mp3",
  "loop": true,
  "presetTime": 180,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "80",
  "mp3Vol": "-4",
  "playTrioBass": true,
  "playTrioDrums": true, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": false, 
  "instruments": [
    {
      "instrumentName": "piano",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "G#",
      "scale": "minor"
    },
    {
      "instrumentName": "harp",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "G#",
      "scale": "minor"
    },
    {
      "instrumentName": "synthtom",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "G#",
      "scale": "minor"
    },
    {
      "instrumentName": "marimba",
      "volume": "-5",
      "noteDuration": "4",
      "whiteKeysMin": "3",
      "whiteKeysMax": "58",
      "key": "G#",
      "scale": "minor"
    }
  ],
  "instrumentTrioBass":
  {
    "instrumentName": "guitar-nylon",
    "volume": "0",
    "noteDuration": "4",
    "whiteKeysMin": "22",
    "whiteKeysMax": "75",
    "key": "G#",
    "scale": "major"
  }
}`;

var gameBasePresetJson = `{
  "name": "GameBase",
  "visible": false,
  "mp3": null,
  "loop": true,
  "presetTime": 180,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "80",
  "mp3Vol": "-4",
  "playTrioBass": false,
  "playTrioDrums": false, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": false, 
  "instruments": [
    {
      "instrumentName": "piano",
      "volume": "-5",
      "noteDuration": "4",
      "whiteKeysMin": "5",
      "whiteKeysMax": "80",
      "key": "C",
      "scale": "none"
    },
    {
      "instrumentName": "harp",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "5",
      "whiteKeysMax": "80",
      "key": "C",
      "scale": "none"
    },
    {
      "instrumentName": "synthtom",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "C",
      "scale": "none"
    },
    {
      "instrumentName": "marimba",
      "volume": "-5",
      "noteDuration": "4",
      "whiteKeysMin": "3",
      "whiteKeysMax": "58",
      "key": "C",
      "scale": "none"
    }
  ],
  "instrumentTrioBass":
  {
    "instrumentName": "guitar-nylon",
    "volume": "0",
    "noteDuration": "4",
    "whiteKeysMin": "22",
    "whiteKeysMax": "75",
    "key": "C",
    "scale": "major"
  }
}`;



var bigfootPresetJson = `{
  "name": "Big Foot",
  "visible": true,
  "mp3": "bigfoot.mp3",
  "loop": true,
  "presetTime": 180,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "80",
  "mp3Vol": "-5",
  "playTrioBass": false,
  "playTrioDrums": true, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": true, 
  "instruments":[{"instrumentName":"cello","volume":-6,"noteDuration":5,"whiteKeysMin":11,"whiteKeysMax":85,"key":"C","scale":"major"},{"instrumentName":"Kalimba","volume":5,"noteDuration":5,"whiteKeysMin":24,"whiteKeysMax":75,"key":"C","scale":"major"},{"instrumentName":"duosynth2","volume":"-16.5","noteDuration":5,"whiteKeysMin":20,"whiteKeysMax":80,"key":"C","scale":"major"},{"instrumentName":"synthtom","volume":0,"noteDuration":5,"whiteKeysMin":15,"whiteKeysMax":85,"key":"C","scale":"major"}],
  "instrumentTrioBass":
  {
    "instrumentName": "guitar-nylon",
    "volume": "0",
    "noteDuration": "4",
    "whiteKeysMin": "22",
    "whiteKeysMax": "75",
    "key": "C",
    "scale":  "major"
  }
  }`;

var greeneggsPresetJson = `{
  "name": "Green Eggs and Ham",
  "visible": true,
  "mp3": "greeneggs.mp3",
  "loop": true,
  "presetTime": 180,
  "pauseWhenPlaying": false,
  "quant": "1/4",
  "bpm": "80",
  "mp3Vol": "0",
  "playTrioBass": true,
  "playTrioDrums": true, 
  "drumVolume": "33", 
  "playRandomMovieSoundRegularly": false, 
  "playRandomSoundRegularly": false, 
  "playRandomNatureSoundRegularly": false, 
  "instruments": [
    {
      "instrumentName": "piano",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "C",
      "scale": "major"
    },
    {
      "instrumentName": "bass-electric",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "C",
      "scale": "major"
    },
    {
      "instrumentName": "guitar-electric",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "55",
      "key": "C",
      "scale": "major"
    },
    {
      "instrumentName": "marimba",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "47",
      "whiteKeysMax": "87",
      "key": "C",
      "scale": "major"
    }
  ],
  "instrumentTrioBass":
    {
      "instrumentName": "guitar-nylon",
      "volume": "0",
      "noteDuration": "4",
      "whiteKeysMin": "22",
      "whiteKeysMax": "75",
      "key": "C",
      "scale": "major"
    }
}`;

class Preset {
  constructor(jsonAsObj, instruments, instrumentTrioBass) {
    this.name = jsonAsObj.name;
    this.visible = jsonAsObj.visible;
    this.instruments = instruments;
    this.mp3 = jsonAsObj.mp3;
    this.mp3Vol = jsonAsObj.mp3Vol;
    this.scale = jsonAsObj.scale;
    this.key = jsonAsObj.key;
    this.bpm = jsonAsObj.bpm;
    this.quant = jsonAsObj.quant;

    this.playTrioBass = jsonAsObj.playTrioBass;
    this.playTrioDrums = jsonAsObj.playTrioDrums;
    this.drumVolume = jsonAsObj.drumVolume;
    this.playRandomMovieSoundRegularly = jsonAsObj.playRandomMovieSoundRegularly;
    this.playRandomSoundRegularly = jsonAsObj.playRandomSoundRegularly;
    this.playRandomNatureSoundRegularly = jsonAsObj.playRandomNatureSoundRegularly;
    this.instrumentTrioBass = instrumentTrioBass;
  }

  static constructFromJson(json) {
    const obj = JSON.parse(json);
    let instrumentsJson = obj.instruments;
    let instruments = [];
    instrumentsJson.forEach((instrument) => {
      // instrument.instrument = this.instruments[instrument.instrumentName]
      instruments.push(Instrument.constructFromObject(instrument));
    });
    let instrumentTrioBass = Instrument.constructFromObject(obj.instrumentTrioBass);
    return new Preset(obj, instruments, instrumentTrioBass);
  }

  toJson() {
    let returnObject = {};
    returnObject["mp3"] = this.mp3;
    returnObject["scale"] = this.scale;
    returnObject["key"] = this.key;
    returnObject["mp3Vol"] = this.mp3Vol;
    returnObject["bpm"] = this.bpm;
    returnObject["quant"] = this.quant;

    returnObject["playTrioBass"] = this.playTrioBass;
    returnObject["playTrioDrums"] = this.playTrioDrums;
    returnObject["drumVolume"] = this.drumVolume;
    returnObject["playRandomMovieSoundRegularly"] = this.playRandomMovieSoundRegularly;
    returnObject["playRandomSoundRegularly"] = this.playRandomSoundRegularly;
    returnObject["playRandomNatureSoundRegularly"] = this.playRandomNatureSoundRegularly;

    let instrumentObjs = [];
    this.instruments.forEach((instrument) => {
      instrumentObjs.push(JSON.parse(instrument.toJson()));
    });
    returnObject["instruments"] = instrumentObjs;
    return JSON.stringify(returnObject);
  }
}

var leadPreset = Preset.constructFromJson(leadPresetJson);
var greenPreset = Preset.constructFromJson(greeneggsPresetJson);
var chillPreset = Preset.constructFromJson(chilloutDubPresetJson);
var gregorianChantPreset = Preset.constructFromJson(gregorianchantJson);
var bigfootPresetPreset = Preset.constructFromJson(bigfootPresetJson);


presetArray[leadPreset.name] = leadPreset;
presetArray[greenPreset.name] = greenPreset;
presetArray[chillPreset.name] = chillPreset;
presetArray[gregorianChantPreset.name] = gregorianChantPreset;
presetArray[bigfootPresetPreset.name] = bigfootPresetPreset;
// presetArray[gameLeadPreset.name] = gameLeadPreset;

// --------------------

var gameBasePreset = Preset.constructFromJson(gameBasePresetJson); 
var gameLeadPreset = Preset.constructFromJson(gameBasePresetJson);
gameLeadPreset.mp3='lead.mp3';
gameLeadPreset.name='GameLead';
var gameDrumPreset = Preset.constructFromJson(gameBasePresetJson); 
gameDrumPreset.playTrioDrums=true;
gameDrumPreset.name='GameDrum';
gameDrumPreset.instruments[0].volume=-22;
gameDrumPreset.instruments[1].volume=-11;
gamePresetArray[gameBasePreset.name] = gameBasePreset;
gamePresetArray[gameLeadPreset.name] = gameLeadPreset;
gamePresetArray[gameDrumPreset.name] = gameDrumPreset;



console.log(presetArray);

const allPresets = Object.freeze(presetArray);
const gamePresets = Object.freeze(gamePresetArray);
export { gamePresets, allPresets, Preset };