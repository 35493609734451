import React, { Component } from "react";
import { Dropdown } from "react-dropdown-now";
import { Button, Card, Row, Col } from "react-bootstrap";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { wtfKeys } from "./Onboarding";

const Presets = (props) => {
  const setPresetRotateDurationSlider = (event) => {
    console.log("preset duration set: " + event.target.value);
    props.setPresetRotateDuration(event.target.value);
  };


  function changeRotatePresets(event) {
    console.log(event);
    props.setRotatePresets(event.target.checked);
  }


  return (
      <div
      id='presets'
      style={ 
      (props.wtf < wtfKeys.indexOf("PRESETS")) ? { display: 'none' } : 
      {
        marginRight: "11px",
        marginBottom: '5px',
        border: '2px solid rgb(8 160 19)', 
        background: 'antiquewhite',
        display: 'inline-block',
        paddingLeft: '11px',
        paddingRight: '11px'
        }}>
    
        <Dropdown 
        style={{backgroundColor: 'beige'}}
          placeholder="Use a Preset"
          options={props.presetArray}
          value={props.selected}
          onChange={(value) => {
            console.log("change!", value.target);
            props.setPreset(value.value);
          }}
        />

    
{/*  <FormControl 
    variant="standard" 
    sx={{ m: 1, minWidth: 22, width:122 }} 
    style={(props.wtf < wtfKeys.indexOf("PRESETS")) ? { display: 'none' } : {display:'block'
    }}>
    
    <InputLabel id="preset-label">Preset</InputLabel>
    <Select
      autoWidth
      labelId="preset-label"
      // placeholder="Select a key"
      label='Preset'
      value={props.selected}
      displayEmpty={true}
      onChange={(value) => {
        console.log("change!", value.target);
        props.setPreset(value.target.value);
      }}
  >
      {
        props.presetArray.map((preset) => {
            return <MenuItem key={preset} value={preset}>{preset}</MenuItem>
        })
      }
    </Select>
  </FormControl>
  */}
  </div>
  );
};

export default Presets;
