import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { logger } from './Logger';
//import * as mm from "@magenta/music"
import LoadingOverlay from 'react-loading-overlay-ts';
import * as Tone from "tone";
import Instrument from  './Instrument';
// import * as ToneInstruments from './Tonejs-Instruments'

var baseUrl = "/toneinst/samples/";
// var baseUrl = "https://haham.net/wc/toneinst/samples/";

class Loader {
    // static initializeLoader(instrumentsRef, genieRef, setLoading,loadings,setLoadings) {
    //   Loader.genieRef = genieRef;
    //   Loader.instrumentsRef = instrumentsRef;
    //   Loader.setLoading = setLoading;
    //   Loader.loadings = loadings;
    //   Loader.setLoadings = setLoadings;
    // }

    static addLoading(string) {
      // Loader.setLoadings(Loader.loadings.push(string));
    }

    static loadedInstrument(inst = '') {
      console.log("loaded " + inst);
      Loader.rmLoading('instrument ' + inst);
      var r = document.getElementById('overlay')
      r.classList.remove('overlay');
    }

    static rmLoading(string) {

        // Loader.loadings.splice(Loader.loadings.indexOf(string), 1)
        // var loadingsCopy = [...Loader.loadings];
        // Loader.setLoadings(loadingsCopy);
    }

    static getInstrument(instrumentName) {
      if (!toneInstruments[instrumentName]) {
        Loader.addLoading('instrument');
        var r = document.getElementById('overlay')
        r.classList.add('overlay');  
        var instrumentObj = SampleLibrary.load({
          instruments: [instrumentName],
          ext: ".mp3",
          baseUrl: baseUrl,
          onload: Loader.loadedInstrument
        });
        toneInstruments[instrumentName] = instrumentObj[instrumentName];
        toneInstruments[instrumentName].toDestination();        
      }
      return toneInstruments[instrumentName];
    }


}

const Load = (props) => {

    // useEffect(() => {
    //     loadFirstInstrumentsSampler();
    //     loadFirstInstrumentsSimpler();
    //     Loader.loadGenie();
    //   }, []);

    // useEffect(() => {
    //     if (loadings.length > 0) {
    //         props.setLoading(true);
    //     } else {
    //         props.setLoading(false);
    //     }
    // }, [props.loadings]);


    var baseUrl = "/toneinst/samples/";

    function loadFirstInstrumentsSampler() {
        Loader.loadInstrument('piano');
        Loader.loadInstrument('harp');
    }

    // function loadInstrument(name) {
    //     addLoading('instrument');
    //     var instArray = [name];
    //     var instrumentObj = SampleLibrary.load({
    //       instruments: instArray,
    //       ext: ".mp3",
    //       // instruments: ["piano", "cello"],
    //       baseUrl: baseUrl,
    //       onload: loadedInstrument(name)
    //     });
    //     props.toneInstruments.current[name] = instrumentObj[name];
    //     props.toneInstruments.current[name].toMaster();        
    // }


    return (
      <div>
        FJKSDJF;LKJSA
        SFDFJK
        <LoadingOverlay
            active={props.loading}            
            spinner
            text='Loading your content...'
        >
            <div style={{ height: '100vh' }}>
                <p>Some content or children or something.</p>
            </div>
        </LoadingOverlay>
      </div>
    );
};

function show() {
  Load.loadInstrument('piano');
  alert('33');
}

export {Load, show,Loader};
// export function show () {alert('fsdf');};
