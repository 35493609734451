import React, { Component } from "react";
import Button from '@mui/material/Button';
// import { Row, Col,Button, ButtonGroup } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
// import { useState, useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import StopCircleIcon from '@mui/icons-material/StopCircle';
// import RecordCircleIcon from '@mui/icons-material/RecordCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import { wtfKeys } from "./Onboarding";
import { ButtonGroup } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { RWebShare } from "react-web-share";
import SocialModal from "./SocialModal";

// import Typography from "@mui/material";

class RecordingSingleton {
    constructor() {
        if (!RecordingSingleton.instance) {
            this._data = [];
            this._actions = [];
            RecordingSingleton.instance = this;
        }

        return RecordingSingleton.instance;
    }

    load(rec) {
        // this._data.length = 0;
        // this._data.push(...rec.notes);
        this._actions.length = 0;
        this._actions.push(...rec.actions);
    }

    addNote(note) {
        console.log('adding note:' + JSON.stringify(note));
        var action = { data: note, action: 'PLAY_NOTE', time: Date.now() };
        this._actions.push(action);
        // this._data.push(action);
    }

    addAction(action) {
        console.log('adding action:' + JSON.stringify(action));
        this._actions.push(action);
    }

    startRecording() {
        this._actions.length = 0;
    }

    stopRecording() {
    }


    getActions() {
        return this._actions;
    }

}

const recordingObj = new RecordingSingleton();
Object.freeze(recordingObj);

const Recording = (props) => {
    const [recordingResponse, setRecordingResponse] = useState(null);
    const [playerStateState, setPlayerStateState] = useState('stopped');
    const [showSocial, setShowSocial] = useState(false);
    const [socialLink, setSocialLink] = useState('');

    const playerStateRef = useRef('stopped');

    var openShare = true;
    const id = openShare ? 'simple-popover' : undefined;

    const handleClose = () => {
        // setAnchorEl(null);
    };

    useEffect(() => {
        if (props.loadedRecording) {
            recordingObj.load(props.loadedRecording);
            props.instruments[0].setInstrumentName(props.loadedRecording.instrument0);
            props.instruments[1].setInstrumentName(props.loadedRecording.instrument1);
            playBack(recordingObj);
        }
    }, [props.loadedRecording]);

    useEffect(() => {
        if (recordingResponse) {
            console.log('recording response: ' + recordingResponse);
            openShare = true;
            setSocialLink("https://ludwigvanai.com/?playback=" + recordingResponse);
            setShowSocial(true);
            //            alert("https://ludwigvanai.com/?playback=" + recordingResponse, 'Use the following link to share your recording');
        }
    }, [recordingResponse]);

    useEffect(() => {
        if (playerStateRef.current == 'recording' && props.lastPlayedNote) {
            recordingObj.addNote({ time: props.lastPlayedNote.time, note: props.lastPlayedNote.note, instrument: props.lastPlayedNote.instrumentNumber, pitch: props.lastPlayedNote.pitch });
        }
    }, [props.lastPlayedNote]);

    useEffect(() => {
        if (playerStateRef.current == 'recording' && props.lastAction) {
            recordingObj.addAction(props.lastAction);
        }
    }, [props.lastAction]);


    function stop() {
        console.log('stop()');
        if (playerStateRef.current == 'recording') {
            stopRecording();
        } else {
            stopPlaying();
        }
        playerStateRef.current = 'stopped';
        setPlayerStateState(Date.now());
    }

    function play() {
        playBack();
    }

    function playBack() {
        var actions = recordingObj.getActions();
        if (actions.length == 0) {
            console.log('No recording to play!!!!');
            return;
        }
        console.log("play back recording");
        // stop mp3 before playback
        props.stop();
        var startTime = actions[0].time;
        var now = Date.now();
        var startPlayerState = 'playing' + now + startTime;
        playerStateRef.current = startPlayerState;
        actions.forEach(action => {
            var delay = action.time - startTime;
            setTimeout(() => {
                if (playerStateRef.current == startPlayerState) {
                    playbackAction(action);
                }
            },
                delay);
        });

        // after last note, set playerstate
        var delay = actions[actions.length - 1].time - startTime;
        setTimeout(() => {
            if (playerStateRef.current == startPlayerState) {
                playerStateRef.current = 'stopped';
                setPlayerStateState('stopped');
            }
        }, delay);
    };

    function playbackAction(action) {
        switch (action.action) {
            case 'SET_MP3':
                props.setmp3(action.data, false);
                break;
            case 'START_MP3':
                props.playMp3();
                break;
            case 'PAUSE_MP3':
                props.pause();
                break;
            case 'STOP_MP3':
                props.stop();
                break;
            case 'SET_PLAYER_VOLUME':
                props.setPlayerVolume(action.data);
                break;
            case 'PLAY_NOTE':
                props.playbackNote(action.data);
                break;
            default:
                break;
        }
    }

    function stopPlaying() {

    }

    function recordingHasActions() {
        console.log('recordingHasActions');
        if (recordingObj.getActions().length > 0) {
            return true;
        } else {
            return false;
        }
    }

    function stopRecording() {
        playerStateRef.current = 'stopped';
        setPlayerStateState('stopped');
        recordingObj.stopRecording()
    }

    function startRecording() {
        playerStateRef.current = 'recording';
        setPlayerStateState('recording');
        recordingObj.startRecording();
    }

    function closeShareRecording() {
        setRecordingResponse(null);
    }

    function shareRecording() {
        var recording = {
            level: props.levelNum,
            instrument1: props.instruments[1].instrumentName,
            instrument0: props.instruments[0].instrumentName,
            actions: recordingObj.getActions(),
        }
        console.log(JSON.stringify(recording));
        sendRecordingToServer(recording);
    }

    function sendRecordingToServer(recording) {
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", "https://ludwigvanai.com/r.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var response = this.responseText;
                setRecordingResponse(response);
            }
        };
        xhttp.send(JSON.stringify(recording));
    }

    return (
        <center
            id='record_yourself'
            style={(props.wtf < wtfKeys.indexOf("RECORD_YOURSELF")) ? { display: 'none' } : {}}>
            <SocialModal
                showSocial={showSocial}
                setShowSocial={setShowSocial}
                url={socialLink}
            />
            {/* <ButtonGroup   style={(props.wtf < 11) ? {display:'none'} : {}}> */}
            <ButtonGroup>
                <Button
                    size='small'
                    variant="outlined"
                    startIcon={<MicIcon />}
                    onClick={() => startRecording()}
                    disabled={playerStateRef.current != 'stopped'}>
                    {props.small ? '' : 'Rec'}
                </Button>

                <Button
                    size='small'
                    variant="outlined"
                    startIcon={<StopCircleIcon />}
                    onClick={() => stop()}
                    disabled={playerStateRef.current == 'stopped'}>
                    {props.small ? '' : 'Stop'}
                </Button>

                <Button
                    size='small'
                    variant="outlined"
                    startIcon={<PlayCircleIcon />}
                    onClick={() => play()}
                    disabled={recordingObj.getActions().length == 0 || playerStateRef.current.includes('playing') || playerStateRef.current == 'recording'}>
                    {props.small ? '' : 'Play'}
                </Button>
            </ButtonGroup>
            {playerStateRef.current != 'recording' && recordingHasActions() &&
                <Button
                    size='small'
                    variant="outlined"
                    startIcon={<SendIcon />}
                    onClick={() => shareRecording()}
                    disabled={playerStateRef.current == 'recording' || recordingObj.getActions().length == 0}>
                    {props.small ? 'Share' : 'Share Recording'}
                </Button>
            }
            <Modal
                onHide={closeShareRecording}
                show={recordingResponse != null}
                id='recshare'
                className="modal-lg"
            >
                {/* recordingResponse != null} */}
                <Modal.Header closeButton>
                    <Modal.Title>
                        Tool
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'beige' }}>
                    asfdsda asdf{recordingResponse}
                </Modal.Body>
            </Modal>

            {/* <RWebShare
                data={{
                    sites: ['facebook', 'twitter'],
                    text: "Like humansfsad www",
                    url: "https://ssson.natgeo.com/2zHaNup",
                    title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
            >
                <button>Share 🔗</button>
            </RWebShare> */}
        </center>
    )
};

export default Recording;
