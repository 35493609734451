import React, { useRef, useState, useEffect } from "react";
import {Card, ButtonGroup, Button, Modal, Container, Row, Col, ModalBody } from "react-bootstrap";
import { Next } from "react-bootstrap/esm/PageItem";
import Recording from "./Recording";
import SettingsIcon from '@mui/icons-material/Settings';


const Info = (props) => {


    return (
      <div style={{border:"2px"}}>
        {/* <br/>
        <h2>Welcome! </h2>
        <br/> */}
        {/* <center><img src={randimg} width='200' /></center> */}
        {/* <br/> */}
        {/* Play digital instruments and let the AI decide what notes to hit. */}
        {/* <br/>
        <a href='https://magenta.tensorflow.org/'>magenta.tensorflow.org</a>
        <br/><br/><br/>
        First time here?  What's all this?   */}
        <center>
          <br/>
         <a href="#" 
          onClick={() => props.setWTF(0)}
        >Confused</a>
        ?  <a href="https://buymeacoffee.com/lemuel">Want to buy me coffee?</a>
        <br/>
        {/* <Recording
         {...props}
        /> */}

        </center>
      </div>
    );

};

export default Info;
