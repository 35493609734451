const Letter = (props) => {

	var L = props.letter;

	return (
	  <L style={{color:'black', strokeWidth: '2',  background: 'white'}}/>
	);
};

export default Letter;
