import React, { useEffect, useRef, useState } from "react";

var playButton = "<center><Button id='play_level' style={{color: 'black', backgroundColor: 'green', margin: '2px', width: '95px'}}>Play!</Button></center>";
var jamButton = "<center><Button id='play_level' style={{color: 'black', backgroundColor: 'green', margin: '2px', width: '95px'}}>Let's Jam!</Button></center>";
var endButton = "<center><Button id='end_button' style={{color: 'black', backgroundColor: 'green', margin: '2px', width: '95px'}}>Nah, I'm done!</Button></center>";

const gameLevels = [
  {
    preset: 'GameBase',
    video: '/intro.mp4',
    // story: { __html: "In the not so distant future..<br/>Robots are shuned by society.  They've become second class citizens.<br/><br/>You've been asked to <span style='color: red'>put together a band of robots</span> so people see them in a different light.<br/><br/> One robot decided to join you.<br/><br/>He's eager to please.  So eager in fact that he trained an Artificial Intelligence to play the perfect note based on what was played before and when you ask for the next note. <br/><br/>  Might be confusing at first, but let's see if he programmed it right to sound musical.<br/><br/>Let's get started:  <span style='color: red'>Click the robot</span> to make him play a note.<br/><br/>" + playButton },
    story: { __html:`
    Your maker <span style='color: red'>gives you a robot</span> that can't be reprogrammed for factory work.<br/>
    <br/><br/>
    You reprogram him to play music.
    <img src='/images/robotprogramming.jpg' width='300'><br/>
    <br/><br/>
    <strong>Goal:</strong> practice and get used to its choices
<br/><br/>
 `+ playButton  + '<br/><br/>'},
    instructions: { __html: "Click on the robot to tell him when to play.<br/>He'll choose the best note.<br/>You can use the letter D on a keyboard.<br/> <br/> One thing you can do: try different tempos and see which one sounds better to you." },
    keyOf: false,
    recording: true,
    instrument2: false,
    instrument: false,
    drums: false,
    quant: false,
    backgroundImage: '/images/robotconcerthallw.png',
    backgroundImageWidth: 729,
    elements: [{
      image: '/images/hallrobotglowgreen.png', top: 25, left: 260, width: 270, height: 428,
      imageClick: '/images/hallrobotglow.png',
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: "simulateKey('a');"
    }]
  },
  {
    preset: 'GameBase',
    // story: { __html: "Delightful!  <br/>Your <span style='color: red'>timing</span> might not have been perfect.  <br/><br/>You can continue that way or ask the robot to only play your notes precisely to the nearest 1/2 beat, 1/4 beat, etc.<br/><br/> It's called <span style='color: red'>quantization</span>.<br/>" + playButton },
    video: '/decree.mp4',
    story: { __html: `Your playing was nice.  Something pleasant in this dread.<br/> Although your <span style='color: red'>timing</span> might not have been perfect.  <br/><br/>
You update the robot for better timing.  <br/>If you ask him he'll only play your notes on the nearest 1/2 beat, 1/4 beat, etc.<br/> It's called <span style='color: red'>quantization</span>.
<br/>` + playButton},
    instructions: { __html: "Set the speed of quantization, or turn it off!  <br/>" },
    quant: true,
    keyOf: false,
    drums: false,
    recording: false,
    instrument2: false,
    instrument: false,
    backgroundImage: '/images/robotconcerthall2.jpg',
    backgroundImageWidth: 1103,
    elements: [{
      image: '/images/hallrobotglowgreen.png', top: 113, left: 287, width: 190, height: 280,
      imageClick: '/images/hallrobotglow.png',
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    }]
  },
  {
    preset: 'GameBase',
    video: '/scrapmetal.mp4',
    // story: { __html: "You did amazing!<br/><br/>So amazing in fact that <span style='color: red'>three robots</span> have joined the cause.<br/><br/>Click the <span style='color: green'>first robot</span> to <span style='color: red'>go down in pitch</span>, and the <span style='color: green'>third one</span> to <span style='color: red'>go up</span>.<br/><br/>" + playButton },
    story: { __html: `Thanks to your project 3 robots about to get scrapped were able to get rerouted to you.<br/>
<br/>
Please make good use of them.<br/><br/>
Click the <span style='color: green'>first robot</span> to <span style='color: red'>go down in pitch</span>, 
and the <span style='color: green'>third one</span> to <span style='color: red'>go up</span>.
` + playButton},
    instructions: { __html: "Click on the left robot to go down in pitch.  <br/>The right one to go up.  They'll work to make it sound musical." },
    recording: false,
    quant: true,
    keyOf: false,
    instrument: false,
    drums: false,
    instrument2: false,
    backgroundImage: '/images/background_3robots.jpg',
    backgroundImageWidth: 1536,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 194, left: 153, width: 100, height: 300,
      imageClick: '/images/leftrobotglowblack.png',
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'top',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 250, width: 80, height: 220,
      imageClick: '/images/middlerobotglowblack.png', 
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'top',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 258, left: 450, width: 100, height: 220,
      imageClick: '/images/rightrobotglowblack.png', 
      strokeColor: 'green',
      letter: 'F',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    }]
  },
  {
    preset: 'GameBase',
    story: { __html: "Nice!<br/><br/> You should <span style='color: red'>record yourself</span>.<br/><br/>You can also <span style='color: green'>choose the instrument</span> they play.<br/><br/>" + playButton },
    instructions: { __html: "Try different instruments and record away!" },
    recording: true,
    quant: true,
    keyOf: false,
    drums: false,
    instrument: true,
    instrument2: false,
    backgroundImage: '/images/background_3robots.jpg',
    backgroundImageWidth: 1536,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 194, left: 153, width: 100, height: 300,
      imageClick: '/images/leftrobotglowblack.png',
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'top',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 250, width: 80, height: 220,
      imageClick: '/images/middlerobotglowblack.png', 
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'top',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 258, left: 450, width: 100, height: 220,
      imageClick: '/images/rightrobotglowblack.png',
      //, top: 240, left: 440, width: 100, height: 220,
      strokeColor: 'green',
      letter: 'F',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    }]
  },
  {
    preset: 'GameLead',
    video: '/goodnews.mp4',
    story: { __html: `A human is joining your band!<br/>  
    Well, half human.  She's allowed to interact with humans at least. <br/><br/>
    This <span style='color: red'>half-robot singer</span> used to be really popular.<br/>
    <img src='/images/singer.jpg' width='250' ><br/>
    <br/>Let's figure out how to play together.<br/><br/>` + playButton },
    instructions: { __html: "See how it sounds when you play during her singing and/or during the silences in between.<br/>Try different instruments to see what works best." },
    recording: false,
    quant: true,
    drums: false,
    keyOf: false,
    instrument2: false,
    instrument: true,
    backgroundImage: '/images/lead_background.jpg',
    backgroundImageWidth: 1536,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 195, left: 4, width: 77, height: 250,
      imageClick: '/images/leftrobotglowblack.png',
            strokeColor: 'green',
      letter: 'S',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 97, width: 88, height: 195,
      imageClick: '/images/middlerobotglowblack.png', 
            strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 258, left: 299, width: 88, height: 187,
      imageClick: '/images/rightrobotglowblack.png',
      letter: 'F',
      strokeColor: 'green',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    }]
  },

  {
    preset: 'GameBase',
    story: { __html: `Oh God, she <span style='color: red'>got angry and stormed off</span> in a puff.  
    <br/><br/>She said you weren't playing in key.
    <br/><br/>Your robots apologized to you as they hadn't trained keys and scales.  <br/><br/>It's possible she didn't really want to be involved.  She has human rights and doesn't want to risk those.
    <br/><br/>  Your robots said <span style='color: red'>they've upgraded their software</span> to play in the key you choose.  <br/><br/>To <span style='color: green'>learn about keys</span> try <Button id='spks'>this tool</Button> <br/><br/>` },
    instructions: { __html: "Experiment with keys and two instruments at once." },
    recording: true,
    quant: true,
    drums: false,
    keyOf: true,
    instrument2: true,
    instrument: true,
    backgroundImage: '/images/flyingstage.png',
    backgroundImageWidth: 1323,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 193, left: 156, width: 77, height: 247,
      imageClick: '/images/leftrobotglowblack.png', 
      scale: 0.47,
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 250, width: 88, height: 190,
      imageClick: '/images/middlerobotglowgreen.png',
      scale: 0.47,
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 255, left: 453, width: 88, height: 185,
      imageClick: '/images/rightrobotglowblack.png',
      scale: 0.47,
      letter: 'F',
      strokeColor: 'green',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 53, left: 21, width: 157, height: 130,
      scale: 0.62,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      strokeColor: 'red',
      letter: 'J',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 41, left: 176, width: 138, height: 115,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      strokeColor: 'red',
      scale: 0.54,
      letter: 'K',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 21, left: 303, width: 110, height: 93,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      scale: 0.44,
      letter: 'L',
      strokeColor: 'red',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    }]
  },

  {
    preset: 'GameBase',
    // story: { __html: "Very nice!<br/>It's starting to sound expertly musical.<br/><br/><span style='color: red'>Good news!</span><br/>  We found an discarded robot that would make a great addition.<br/><br/><span style='color: red'>Robot Lloyd</span> can play <span style='color: green'>different tracks</span> to complement the music.<br/><br/>" + playButton },
    story: { __html: `Very nice!<br/>It's starting to sound expertly musical.<br/><br/>
    <br/>  We found an discarded robot that would make a great addition.<br/><br/><span style='color: red'>
    Robot Lloyd</span> can play <span style='color: green'>different tracks</span> to complement the music.<br/><br/>` + playButton },
    instructions: { __html: "Practice with different lyrics or spoken word." },
    recording: true,
    quant: true,
    drums: false,
    keyOf: true,
    instrument2: true,
    instrument: true,
    player: true,
    backgroundImage: '/images/say6robots.png',
    backgroundImageWidth: 1187,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 180, left: 3, width: 77, height: 300,
      imageClick: '/images/leftrobotglowblack.png',
      scale: 0.53,
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'top',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 248, left: 111, width: 88, height: 190,
      imageClick: '/images/middlerobotglowblack.png', 
      scale: 0.53,
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'top',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 252, left: 339, width: 88, height: 190,
      imageClick: '/images/rightrobotglowblack.png', 
      scale: 0.54,
      letter: 'F',
      strokeColor: 'green',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 36, left: 31, width: 177, height: 121 ,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'J',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 0, left: 191, width: 181, height: 130,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'K',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: -19, left: 362, width: 118, height: 110,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      scale: 0.5, 
      letter: 'L',
      strokeColor: 'red',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    }]
  },

  {
    preset: 'GameDrum',
    video: '/draconianlaws.mp4',
    story: { __html: `A robot who had a secure factory job, thanks to his speed and accuracy, <span style='color: green'>quit his job.</span><br/>  He has been training himself to be <span style='color: red'>a drummer</span> and wants to join your mission.  <br/>
    He will jam based on how you play.<br/><br/>` + playButton },
    instructions: { __html: "Start playing and <span style='color: red'>the drummer will join in</span>." },
    recording: true,
    quant: true,
    drums: true,
    keyOf: true,
    instrument2: true,
    instrument: true,
    player: true,
    backgroundImage: '/images/lloyd6robotsdrummer.jpg',
    backgroundImageWidth: 1536,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 190, left: 2, width: 77, height: 252,
      imageClick: '/images/leftrobotglowblack.png', 
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 97, width: 88, height: 192,
      imageClick: '/images/middlerobotglowblack.png',
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 257, left: 300, width: 88, height: 185,
      imageClick: '/images/rightrobotglowblack.png', 
      letter: 'F',
      strokeColor: 'green',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 64, left: 15, width: 127, height: 95,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'J',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 23, left: 140, width: 128, height: 100,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'K',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 0, left: 252, width: 88, height: 85,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      scale: 0.4,
      letter: 'L',
      strokeColor: 'red',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    }
    ]
  },
  {
    preset: 'GameDrum',
    video: '/bomb.mp4',
    story: { __html: `
You might not be completely ready, but <span style='color: red'>we have to put on a show now</span>.<br/>
<br/>Do it for the welfare of all us robots.  
<br/>Maybe we won't be seen as <span style='color: green'>cold pieces of metal</span>.<br/><br/>
But whatever you do, follow the guidelines and don't misbehave!<br/>  
Good luck!<br/><br/>` + playButton },
    // instructions: { __html: "This is it!" },
    recording: true,
    drums: true,
    quant: true,
    keyOf: true,
    instrument2: true,
    instrument: true,
    player: true,
    backgroundImage: '/images/concert.jpg',
    backgroundImageWidth: 2015,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 147, left: 50, width: 88, height: 193,
      imageClick: '/images/leftrobotglowblack.png', 
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 190, left: 121, width: 66, height: 150,
      imageClick: '/images/middlerobotglowblack.png', 
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', 
      imageClick: '/images/rightrobotglowblack.png', top: 195, left: 275, width: 77, height: 145,
      strokeColor: 'green',
      letter: 'F',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 43, left: 52, width: 97, height: 50,
      scale: 0.36,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      strokeColor: 'red',
      letter: 'J',
      letterPosition: 'top',
      mousedown: 'props.play(1,-1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 20, left: 142, width: 97, height: 70,
      scale: 0.34,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      letter: 'K',
      strokeColor: 'red',
      letterPosition: 'bottom',
      mousedown: 'props.play(1,0);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: -4, left: 235, width: 77, height: 75,
      scale: 0.34,
      imageClick: '/images/flyingspeakerrobotglowblack.png',
      letter: 'L',
      letterPosition: 'bottom',
      strokeColor: 'red',
      mousedown: 'props.play(1,1);'
    }
    ]
  },
  {
    preset: 'GameDrum',
    story: { __html: `<audio src="/audio/generativemusic.mp3" id="my_audio" autoplay="autoplay"></audio>
<img src='/images/1.png' class='image'><br/><br/><img src='/images/2.png' class='image'><br/><br/><img src='/images/3.png' class='image'><br/><br/><img src='/images/4.png' class='image'><br/><br/><img src='/images/5.png' class='image'><br/><br/><img src='/images/6.png' class='image'><br/><br/><img src='/images/7.png' class='image'><br/><br/><br/><br/>The robots want to have a celebratory jam session.  You in?<br/>` + jamButton +"<br/><br/>"+ endButton },
    // story: { __html: "Well done.  The humans love us.<br/>Let's have a celebratory jam session.<br/><br/>Small sound robots want to be played on too.<br/><br/>` + playButton },
    // instructions: { __html: "Jam away.  Add sounds." },
    recording: true,
    quant: true,
    drums: true,
    keyOf: true,
    instrument2: true,
    instrument: true,
    player: true,
    backgroundImage: '/images/lloyd6robotsdrummer.jpg',
    backgroundImageWidth: 1536,
    elements: [{
      image: '/images/leftrobotglowgreen.png', top: 190, left: 2, width: 77, height: 250,
      imageClick: '/images/leftrobotglowblack.png', 
      strokeColor: 'green',
      letter: 'S',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/middlerobotglowgreen.png', top: 250, left: 97, width: 88, height: 190,
      imageClick: '/images/middlerobotglowblack.png',
      strokeColor: 'green',
      letter: 'D',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/rightrobotglowgreen.png', top: 257, left: 300, width: 88, height: 183,
      imageClick: '/images/rightrobotglowblack.png', 
      letter: 'F',
      strokeColor: 'green',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 64, left: 15, width: 117, height: 100,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'J',
      letterPosition: 'top',
      mousedown: 'props.play(0,-1);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 23, left: 140, width: 118, height: 100,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      strokeColor: 'red',
      letter: 'K',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,0);'
    },
    {
      image: '/images/flyingspeakerrobotglowred.png', top: 0, left: 252, width: 99, height: 85,
      imageClick: '/images/flyingspeakerrobotglowblack.png', 
      scale: 0.4,
      letter: 'L',
      strokeColor: 'red',
      letterPosition: 'bottom',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/roundrobotglowpurple.png', top: 400, left: 380, width: 110, height: 80,
      imageClick: '/images/roundrobotglowblack.png',
      strokeColor: 'blue',
      letter: 'U',
      text: 'Nature Sounds',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/roundrobotglowpurple.png', top: 398, left: 500, width: 100, height: 90,
      imageClick: '/images/roundrobotglowblack.png',
      strokeColor: 'blue',
      letter: 'I',
      text: 'Movie Sounds',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    },
    {
      image: '/images/roundrobotglowpurple.png', top: 400, left: 620, width: 100, height: 100,
      imageClick: '/images/roundrobotglowblack.png',
      strokeColor: 'blue',
      letter: 'O',
      text: 'Random Sounds',
      letterPosition: 'top',
      mousedown: 'props.play(0,1);'
    }
      ]
  },
  {
    preset: 'GameBase',
    // story: { __html: "<img src='/images/end1.png' class='image'><br/><br/><img src='/images/end2.png' class='image'><br/><br/>" + endButton },
    instructions: { __html: "Start playing and the drummer will join in." },
    recording: false,
    quant: true,
    keyOf: false,
    instrument2: false,
    instrument: false,
    backgroundImage: '/images/6robotsanddrummer.jpg',
    backgroundImageWidth: 1536,
    elements: []
  }


];

const levels = Object.freeze(gameLevels);
export  {levels};
