import React, { useRef, useState, useEffect } from "react";
import PlayButtons from "./PlayButtons";


const Piano = (props) => {

  // useEffect(() => { play(props.lastPlayedAny, props.lastPlayedInstrument) }, [props.lastPlayedAny, props.lastPlayedInstrument])
  useEffect(() => {
    play(props.lastPlayedAny, props.lastPlayedInstrument)
  },
    [props.lastPlayedAny, props.lastPlayedInstrument]
  )


  useEffect(() => {
    if (props.lastPlayedNote) {
      play(props.lastPlayedNote.note, props.lastPlayedNote.instrumentNumber)
    }
  },
    [props.lastPlayedNote]
  )

  useEffect(() => {
    var notes = getNotes(props.keyOf, props.scale);
    keyClear();
    console.log('notes:');
    setKeyScale(notes);
  }, [props.keyOf, props.scale]);

  const scalesCalc = {
    major: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
    minor: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0],
    none: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  };
  const letterNotes = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#"
  ];

  const getNotes = (key, scale) => {
    var keyIndex = letterNotes.indexOf(key);
    var notes = [];
    // add arrays instead
    for (var i = 12; i <= 99; i++) {
      if (scalesCalc[scale][(i - keyIndex) % 12] == 1) {
        notes.push(i + 9);
      }
    }
    return notes;
  }

  const keyClear = () => {
    for (var i = 21; i < 108; i++) {
      var b = document.getElementById(props.prefix + i);
      b.classList.remove('key');
    }
  }

  const grayOut = () => {
    for (var i = 21; i < 108; i++) {
      var b = document.getElementById(props.prefix + i);
      b.classList.add('keyGray');
    }
  }

  const setKeyScale = (notes) => {
    grayOut();
    notes.forEach(note => {
      var b = document.getElementById(props.prefix + note);
      b.classList.remove('keyGray');
    })
  }

  //   const setKeyScale = (notes) => {
  //     notes.forEach(note => {
  //         var b = document.getElementById(note);
  //         b.classList.add('key');
  //     })
  //   }

  const play = (note, num) => {
    if (note == "") { return; }
    console.log("PLAYPIANO " + note);
    var noteNum = Tone.Frequency(note).toMidi();
    console.log("PLAYPIANO NOTENUM " + noteNum + ' instrument ' + num);
    // var a = this.refs[note];
    // a.classList.add("pressed" + num);
    if (noteNum > 108) {
      noteNum = 108;
    }
    if (noteNum < 21) {
      noteNum = 21;
    }
    var b = document.getElementById(props.prefix + noteNum);
    b.classList.add("pressed" + num);
    setTimeout(() => rm(noteNum, num), 555);
  }

  const rm = (noteNum, num) => {
    var b = document.getElementById(props.prefix + noteNum);
    b.classList.remove("pressed" + num);
  }
  var r = props.prefix;
  return (
    <div>

      <svg
        id="piano-keyboard"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 150"
        style={{ marginTop: "-14px" }}//, marginLeft: "68px"}}
      >
        <defs>
          <style>
            {`.cls-1 {
            fill: #fff;
          }

          .cls-1,
          .cls-2 {
            stroke: #000;
            stroke-miterlimit: 10;
          }
        .key {
            fill: gray;
            // fill-opacity: 30%;
            z-index: 222;
            // stroke-width: 5px;
            // stroke: gray;
        }
        .keyGray {
            fill: gray;
            // fill-opacity: 30%;
            z-index: 222;
            // stroke-width: 5px;
            // stroke: gray;
        }

          .pressed0 {
            fill: cyan;
            z-index: 555;
            fill-opacity: 100%;
          }

          .pressed1 {
            fill: magenta;
            z-index: 555;
            fill-opacity: 100%;
          }

          .pressed2 {
            fill: yellow;
            z-index: 555;
            fill-opacity: 100%;
          }
          .pressed3 {
            fill: brown;
            z-index: 555;
            fill-opacity: 100%;
          }
          .pressed11 {
            fill: SpringGreen;
            z-index: 555;
            fill-opacity: 100%;
          }
          `}
          </style>
        </defs>
        <rect id={r + "108"} className="cls-1" x="1196" y="1" width="23" height="149" />
        <rect id={r + "107"} className="cls-1" x="1172" y="1" width="24" height="149" />
        <rect id={r + "105"} className="cls-1" x="1149" y="1" width="23" height="149" />
        <rect id={r + "103"} className="cls-1" x="1126" y="1" width="23" height="149" />
        <rect id={r + "101"} className="cls-1" x="1102" y="1" width="24" height="149" />
        <rect id={r + "100"} className="cls-1" x="1079" y="1" width="23" height="149" />
        <rect id={r + "98"} className="cls-1" x="1055" y="1" width="24" height="149" />
        <rect id={r + "96"} className="cls-1" x="1032" y="1" width="23" height="149" />
        <rect id={r + "95"} className="cls-1" x="1008" y="1" width="24" height="149" />
        <rect id={r + "93"} className="cls-1" x="985" y="1" width="23" height="149" />
        <rect id={r + "91"} className="cls-1" x="962" y="1" width="23" height="149" />
        <rect id={r + "89"} className="cls-1" x="938" y="1" width="24" height="149" />
        <rect id={r + "88"} className="cls-1" x="915" y="1" width="23" height="149" />
        <rect id={r + "86"} className="cls-1" x="891" y="1" width="24" height="149" />
        <rect id={r + "84"} className="cls-1" x="868" y="1" width="23" height="149" />
        <rect id={r + "83"} className="cls-1" x="844" y="1" width="24" height="149" />
        <rect id={r + "81"} className="cls-1" x="821" y="1" width="23" height="149" />
        <rect id={r + "79"} className="cls-1" x="798" y="1" width="23" height="149" />
        <rect id={r + "77"} className="cls-1" x="774" y="1" width="24" height="149" />
        <rect id={r + "76"} className="cls-1" x="751" y="1" width="23" height="149" />
        <rect id={r + "74"} className="cls-1" x="727" y="1" width="24" height="149" />
        <rect id={r + "72"} className="cls-1" x="704" y="1" width="23" height="149" />
        <rect id={r + "71"} className="cls-1" x="680" y="1" width="24" height="149" />
        <rect id={r + "69"} className="cls-1" x="657" y="1" width="23" height="149" />
        <rect id={r + "67"} className="cls-1" x="634" y="1" width="23" height="149" />
        <rect id={r + "65"} className="cls-1" x="610" y="1" width="24" height="149" />
        <rect id={r + "64"} className="cls-1" x="587" y="1" width="23" height="149" />
        <rect id={r + "62"} className="cls-1" x="563" y="1" width="24" height="149" />
        <rect id={r + "60"} className="cls-1" x="540" y="1" width="23" height="149" />
        <rect id={r + "59"} className="cls-1" x="516" y="1" width="24" height="149" />
        <rect id={r + "57"} className="cls-1" x="493" y="1" width="23" height="149" />
        <rect id={r + "55"} className="cls-1" x="470" y="1" width="23" height="149" />
        <rect id={r + "53"} className="cls-1" x="446" y="1" width="24" height="149" />
        <rect id={r + "52"} className="cls-1" x="423" y="1" width="23" height="149" />
        <rect id={r + "50"} className="cls-1" x="399" y="1" width="24" height="149" />
        <rect id={r + "48"} className="cls-1" x="376" y="1" width="23" height="149" />
        <rect id={r + "47"} className="cls-1" x="352" y="1" width="24" height="149" />
        <rect id={r + "45"} className="cls-1" x="329" y="1" width="23" height="149" />
        <rect id={r + "43"} className="cls-1" x="306" y="1" width="23" height="149" />
        <rect id={r + "41"} className="cls-1" x="282" y="1" width="24" height="149" />
        <rect id={r + "40"} className="cls-1" x="259" y="1" width="23" height="149" />
        <rect id={r + "38"} className="cls-1" x="235" y="1" width="24" height="149" />
        <rect id={r + "36"} className="cls-1" x="212" y="1" width="23" height="149" />
        <rect id={r + "35"} className="cls-1" x="188" y="1" width="24" height="149" />
        <rect id={r + "33"} className="cls-1" x="165" y="1" width="23" height="149" />
        <rect id={r + "31"} className="cls-1" x="142" y="1" width="23" height="149" />
        <rect id={r + "29"} className="cls-1" x="118" y="1" width="24" height="149" />
        <rect id={r + "28"} className="cls-1" x="95" y="1" width="23" height="149" />
        <rect id={r + "26"} className="cls-1" x="71" y="1" width="24" height="149" />
        <rect id={r + "24"} className="cls-1" x="48" y="1" width="23" height="149" />
        <rect id={r + "23"} className="cls-1" x="24" y="1" width="24" height="149" />
        <rect id={r + "21"} className="cls-1" x="1" y="1" width="23" height="149" />
        <rect id={r + "22"} className="cls-2" x="17.5" y="1" width="13" height="98" />
        <rect id={r + "25"} className="cls-2" x="64.5" y="1" width="13" height="98" />
        <rect id={r + "27"} className="cls-2" x="88.5" y="1" width="13" height="98" />
        <rect id={r + "30"} className="cls-2" x="135.5" y="1" width="13" height="98" />
        <rect id={r + "32"} className="cls-2" x="158.5" y="1" width="13" height="98" />
        <rect id={r + "34"} className="cls-2" x="181.5" y="1" width="13" height="98" />
        <rect id={r + "37"} className="cls-2" x="228.5" y="1" width="13" height="98" />
        <rect id={r + "39"} className="cls-2" x="252.5" y="1" width="13" height="98" />
        <rect id={r + "42"} className="cls-2" x="299.5" y="1" width="13" height="98" />
        <rect id={r + "44"} className="cls-2" x="322.5" y="1" width="13" height="98" />
        <rect id={r + "46"} className="cls-2" x="345.5" y="1" width="13" height="98" />
        <rect id={r + "49"} className="cls-2" x="392.5" y="1" width="13" height="98" />
        <rect id={r + "51"} className="cls-2" x="416.5" y="1" width="13" height="98" />
        <rect id={r + "54"} className="cls-2" x="463.5" y="1" width="13" height="98" />
        <rect id={r + "56"} className="cls-2" x="486.5" y="1" width="13" height="98" />
        <rect id={r + "58"} className="cls-2" x="509.5" y="1" width="13" height="98" />
        <rect id={r + "61"} className="cls-2" x="556.5" y="1" width="13" height="98" />
        <rect id={r + "63"} className="cls-2" x="580.5" y="1" width="13" height="98" />
        <rect id={r + "66"} className="cls-2" x="627.5" y="1" width="13" height="98" />
        <rect id={r + "68"} className="cls-2" x="650.5" y="1" width="13" height="98" />
        <rect id={r + "70"} className="cls-2" x="673.5" y="1" width="13" height="98" />
        <rect id={r + "73"} className="cls-2" x="720.5" y="1" width="13" height="98" />
        <rect id={r + "75"} className="cls-2" x="744.5" y="1" width="13" height="98" />
        <rect id={r + "78"} className="cls-2" x="791.5" y="1" width="13" height="98" />
        <rect id={r + "80"} className="cls-2" x="814.5" y="1" width="13" height="98" />
        <rect id={r + "82"} className="cls-2" x="837.5" y="1" width="13" height="98" />
        <rect id={r + "85"} className="cls-2" x="884.5" y="1" width="13" height="98" />
        <rect id={r + "87"} className="cls-2" x="908.5" y="1" width="13" height="98" />
        <rect id={r + "90"} className="cls-2" x="955.5" y="1" width="13" height="98" />
        <rect id={r + "92"} className="cls-2" x="978.5" y="1" width="13" height="98" />
        <rect id={r + "94"} className="cls-2" x="1001.5" y="1" width="13" height="98" />
        <rect id={r + "97"} className="cls-2" x="1048.5" y="1" width="13" height="98" />
        <rect id={r + "99"} className="cls-2" x="1072.5" y="1" width="13" height="98" />
        <rect id={r + "102"} className="cls-2" x="1119.5" y="1" width="13" height="98" />
        <rect id={r + "104"} className="cls-2" x="1142.5" y="1" width="13" height="98" />
        <rect id={r + "106"} className="cls-2" x="1165.5" y="1" width="13" height="98" />
      </svg>
    </div>
  )
}

export default Piano;