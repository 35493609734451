import React, { useState } from "react";
import { Dropdown, Selection } from "react-dropdown-now";
// import { Button } from "react-bootstrap";
import { Button } from "@mui/material";
// import Stack from "@mui/material";
import { Stack } from "@mui/system";
import SettingsIcon from '@mui/icons-material/Settings';
import {TbLetterQ,TbLetterW,TbLetterE,TbLetterR,TbLetterZ,TbLetterX,TbLetterC,TbLetterV,TbLetterF, TbLetterA, TbLetterD, TbLetterS} from 'react-icons/tb';
import Letter  from "./Letter";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { wtfKeys } from "./Onboarding";
import {ListItem, ListItemText , FormLabel, List} from '@mui/material';
import { IconButton } from "@mui/material";
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const InstrumentButtons = (props) => {
    const setDropDownInstrumentName = (event) => {
        console.log("instr name: " + event.target.value);
        // setInstrumentName(event.value);
        // props.instrument.setInstrument(instruments[event.value]);
        props.instruments[props.num].setInstrumentName(event.target.value);
      };
    
  return (
    <div
        style={{width: '100%', marginBottom: '5px'}}>
        <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", zIndex: 2,  backgroundColor: "white", position: "absolute", fontSize: "1.25em"}}>
            <MusicNoteIcon/><strong>{props.lastPlayed[props.num]}</strong><MusicNoteIcon/>
        </FormLabel>
{/* <List dense sx={{ borderRadius: 1, border: 1, borderColor: 'grey.600', "&:hover": { borderColor: 'grey.200' }}}>
   <ListItem secondaryAction={<IconButton edge="end" aria-label="delete">del</IconButton>}>
      <ListItemText primary="primary" secondary="group id"/>
   </ListItem>
</List> */}
<FormControl dense sx={{ display:'flex', margin: '0px',borderRadius: 1, border: 2, padding: '5px', borderColor: 'grey.600', "&:hover": { border:3, borderColor: 'black' }}}>

    <center>
        <div 
        id={props.num +"edit_instrument"}
        style={(props.wtf < wtfKeys.indexOf("EDIT_INSTRUMENT")) ? {display:'none'} : {display: 'block'}} 
        >

<FormControl variant="standard" sx={{ m: 1, margin: '0px',minWidth: 22, width:122 }} >    
    <Select
      autoWidth
      labelId="preset-label"
      // placeholder="Select a key"
      label='Preset'
      value={props.instruments[props.num].instrumentName}
      onChange={(value) => {
          console.log("change!", value);
          setDropDownInstrumentName(value);
      }}
  >
      {
        props.instrumentOptions.map((inst) => {
            return <MenuItem key={inst} value={inst}>{inst}</MenuItem>
        })
      }
    </Select>
  </FormControl>
        {/* <Dropdown
            placeholder="inst"
            // className="my-className"
            options={props.instrumentOptions}
            value={props.instruments[props.num].instrumentName}
            onChange={(value) => {
                console.log("change!", value);
                setDropDownInstrumentName(value);
            }}
        /> */}
        <SettingsIcon
            onClick={() => props.edit(props.num)}
            // fontSize='large'
        />
 
        {/* <a href="#"><PencilSquare onClick={() => props.edit(props.num)}></PencilSquare></a> */}
        </div>
       <br/>
       {/* <Stack spacing={11} direction="column"> */}

        <Button 
            id={props.num + "high"}
            style={(props.wtf < wtfKeys.indexOf("UP_DOWN")) ? {display:'none'} : 
            { borderColor: "black", borderWidth:'3px', color:props.color, backgroundColor:props.bgc, 
            borderRadius:'65px 65px 0 0', margin:'2px', width: '90%', display: 'revert', 
            "&:hover": { backgroundColor:'red', border:11, borderColor: 'red' } }}
            variant='outlined'
                onMouseDown={() => props.play(props.num,1)}
                onMouseUp={() => props.release(props.num,1)}
                // onClick={() => props.play(props.num,1)}
            // sx={ { borderRadius: 28 } }
        >
            <MusicNoteIcon style={{float:'left'}}/>
        <strong>High&nbsp;</strong>
        <span style={{float:'right'}}><Letter letter={props.letterHigh}/></span>
        </Button>
        {/* <br/> */}
        <Button
            variant='outlined'
            sx={{ borderRadius: 0 }}
            id={props.num + "reg"}
            style={{        borderColor: "black", borderWidth:'3px',
            color:props.color, backgroundColor:props.bgc, margin:'2px',  width: '90%', display: 'revert'}} //,fontSize: "1.3rem" }} //minHeight:'100px',
            onMouseDown={() => props.play(props.num,0)}
            onMouseUp={() => props.release(props.num,0)}
        // onClick={() => props.play(props.num,0)}
        >
            <MusicNoteIcon  style={{float:'left'}} />
            <span style={{float:'right'}}><Letter letter={props.letter}/></span>
            {/* <br/> */}
            {/* <strong>{props.instruments[props.num].instrumentName}</strong>
            <br/> */}
            &nbsp;
            {/* {props.lastPlayed[props.num]} */}
        </Button>
        {/* <br /> */}
        <Button 
            variant='outlined'
            sx={{ borderRadius: 28 }}
            id={props.num + "low"}
            style={(props.wtf < wtfKeys.indexOf("UP_DOWN")) ? {display:'none'} : 
            { borderRadius:' 0 0 25px 25px', borderColor: "black", borderWidth:'3px',
             color:props.color, backgroundColor:props.bgc, margin:'2px', width: '90%', display: 'revert'}}
            onMouseDown={() => props.play(props.num,-1)}
            onMouseUp={() => props.release(props.num,-1)}
        // onClick={() => props.play(props.num,-1)}
        >
            <MusicNoteIcon
                style={{float:'left'}}/>
            <strong>Low</strong>&nbsp;
            <span style={{float:'right'}}><Letter letter={props.letterLow}/></span>
        </Button>
        {/* </Stack> */}
        </center>

        </FormControl>

    </div>
  );
};

// const [modalShow, setModalShow] = useState(false);
export default InstrumentButtons;
