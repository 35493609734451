import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import ReactTooltip from "react-tooltip";
import {TbQuestionMark} from 'react-icons/tb';
import { Stack } from "@mui/system";
import { wtfKeys } from "./Onboarding";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';


const KeyOf = (props) => {
  var keys = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#"
  ];
  var scales = ["none", "major", "minor"];

  return (
        <Stack spacing={1} direction="row" 
        style={(props.wtf < wtfKeys.indexOf("KEY_SCALE")) ? {display:'none'} : {marginRight: "11px", paddingBottom: "11px" }}
        >
            <FormControl variant="standard" sx={{ m: 1, margin: '0px',minWidth: 22, width: 122 }} >
              <InputLabel id="keys-label">Key</InputLabel>
              <Tooltip placement="left-start" title="Set the key all notes will be played in.">
             <Select
                autoWidth
                id={"keyid"}
                labelId="keys-label"
                placeholder="Select a key"
                label='key'
                value={props.keyOf}
                onChange={(value) => {
                  props.setKeyOf(value.target.value);
                }}
              >
                {
                  keys.map((key) => {
                    return <MenuItem value={key}>{key}</MenuItem>
                  })
                }
              </Select>
              </Tooltip>
            </FormControl>

          <FormControl variant="standard" sx={{ m: 1, margin: '0px',minWidth: 22, width: 122 }} >
            <InputLabel id="simple-select-standard-label">Scale</InputLabel>
            <Tooltip placement="left-start" title="Set the Scale all notes will be played in.">
            <Select
              autoWidth
              labelId="simple-select-standard-label"
              placeholder="Select a scale"
              label='Scale'
              value={props.scale}
              onChange={(value) => {
                console.log("change scale", value.target);
                props.setScale(value.target.value);
              }}
              onSelect={(value) => console.log("selected!", value)} // always fires once a selection happens even if there is no change
              onClose={(closedBySelection) =>
                console.log("closedBySelection?:", closedBySelection)
              }
              onOpen={() => console.log("open!")}
            >
              {
                scales.map((scale) => {
                  return <MenuItem value={scale}>{scale}</MenuItem>
                })
              }
            </Select>
            </Tooltip>
          </FormControl>
    </Stack>
  );
};

// const [modalShow, setModalShow] = useState(false);
export default KeyOf;
