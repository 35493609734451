import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { Dropdown, Selection } from "react-dropdown-now";
import Button from '@mui/material/Button';
import { Stack } from "@mui/system";
import { JAM_LEVEL } from './GameModal';


const divStyle = {
  zIndex: '222',
  // width: '88%',
  // height: '100%',
  backgroundImage: `url(/images/spash.jpg)`,
  backgroundSize: 'cover'
};

const WelcomeModal = (props) => {
  async function preloadVideo(src) {
    const res = await fetch(src);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }



  useEffect(() => {
    console.log('params:' + props.params);
    if (props.params.playback) {
      if (confirm("Play back the recording?")) {
        fetch("https://ludwigvanai.com/play.php?id=" + props.params.playback)
          .then((response) => response.json())
          .then((json) => {
            console.log(json);
            console.log('setting level: ' + json.level)
            props.setLoadedRecording(json);
            props.setLevelNum(parseInt(json.level));
            props.gameModalForward();
          })
      } else { alert('OK, enjoy the game without playback'); }
      console.log("confirm alert playback");
      return;
    }
    if (props.params.l) {
      console.log('setting level: ' + props.params.l)
      props.setLevelNum(parseInt(props.params.l));
      props.gameModalForward();
    }
  }, [props.params]);


  useEffect(() => {
    preloadVideo('/intro.mp4');


    if (window.location.pathname.includes('jam')) {
      props.setJamming(true);
      props.setLevelNum(JAM_LEVEL);
      props.gameModalForward();

      // Soundfont.instrument(new AudioContext(), 'acoustic_grand_piano').then(function (piano) {
      //   piano.play('C4')
      // });

      // console.log("INIT SF");
      // const hd = new mm.SoundFontPlayer('https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus');//('/audio/hd/hd.sfz');
      // hd.soundFont.initialize().then(function () {
      //   hd.playNoteDown({ "pitch": 44, "instrument": 1, "isDrum": false, "velocity": 127 });
      //   console.log('SF');
      // });

      // hd.loadSamples();


    }
  }, []);

  // const welcomeMusic = new Audio("/audio/beeth5.mp3");
  // const [playing, setPlaying] = React.useState(false);

  // function playAudio() {
  //   if (!playing) {
  //     console.log('play!');
  //     welcomeMusic.play();
  //     if (!welcomeMusic.pause) {
  //       setPlaying(true);
  //     }
  //   }
  // }

  function openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  // todo: button sizes based on screen size, magenta font,color;  replace onloadin with this, ludwig size based on scren size
  return (
    <Modal
      id='welcomemodal'
      // onMouseOver={() => { playAudio() }}
      keyboard={false}
      dataKeyboard={false}
      dataBackdrop="static"
      fullscreen={true}
      {...props}
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: '2222', backgroundColor: "burlywood" }}
    >
      <Modal.Body className="show-grid"
        style={divStyle}
      // style={{zIndex:'2222', }}
      >
        {/* <audio  controls autoPlay>
  <source src="/audio/beeth5.mp3" type="audio/mpeg" />
  Your browser does not support the audio element.
</audio> */}
        {/* <Button
          onClick={() => {
            console.log("jamming");
            // props.onHide();
            // props.setWTF(0);
            props.setJamming(true);
            props.setShowGameModal(true);
          }}
          // variant="contained"
          style={{
            fontFamily: 'Orbitron', 
            // position: 'absolute', bottom: 33, left: 33,
            backgroundColor: 'black', color: 'white', fontWeight: 'bold',
            paddingLeft: '33px', paddingRight: '33px'
          }}
        >
          Jam</Button>

        <Button
          onClick={() => {
            props.setShowGameModal(true);
          }}
          style={{
            fontFamily: 'Orbitron', 
            // position: 'absolute', bottom: 33, right: 33,
            backgroundColor: 'black', color: 'white', fontWeight: 'bold',
            paddingLeft: '33px', paddingRight: '33px'
          }}
        >
          Play Game!</Button>

        <Button
          onClick={() => {
            props.onHide();
            props.setWTF(0);
          }}
          style={{
            fontFamily: 'Orbitron',
            //  position: 'absolute', bottom: 33, right: 33,
            backgroundColor: 'black', color: 'white', fontWeight: 'bold',
            paddingLeft: '33px', paddingRight: '33px'
          }}
        >
          use tool</Button>
 */}
        <Container>
          <Row>
            <Col
              style={{ fontFamily: 'Orbitron', color: 'white' }}
            >
              <center><h1
                style={{ fontFamily: 'Orbitron', color: 'white', fontWeight: 'bold' }}
              >
                Ludwig van A.I.</h1></center>
              <center>
                <br />
                <br />
                <h5>Use my musical robots.</h5>
                <br />
                <Button
                  onClick={() => {
                    if (!props.params.hasOwnProperty('dontrfs')) {
                      // if (confirm("Play full screen?") == true) {
                      // if (window.orientation > 1) {
                      openFullscreen(document.querySelector("body"));//.requestFullscreen();
                      screen.orientation.lock('landscape');
                      // }
                    }
                    props.gameModalForward();
                    if (props.params.l) {
                      console.log('setting level: ' + props.params.l)
                      props.setLevelNum(parseInt(props.params.l));
                    } else {
                      props.setLevelNum(0);
                    }
                    try {
                      setTimeout(() => document.querySelector('video').play(), 1555);
                    } catch (e) {
                      console.log('no video for level ' + props.levelNum);
                    }
                  }}
                  style={{
                    fontFamily: 'Orbitron',
                    backgroundColor: 'black', color: 'white', fontWeight: 'bold',
                    paddingLeft: '33px', paddingRight: '33px'
                  }}
                >
                  Play Game!</Button>
                <br />
                <br />

                <Button
                  onClick={() => {
                    if (!props.params.hasOwnProperty('dontrfs')) {
                      // if (confirm("Play full screen?") == true) {
                      // if (window.orientation > 1) {
                      try {
                        openFullscreen(document.querySelector("body"));//.requestFullscreen();
                      } catch (e) {
                        console.log('full screen request not allowed');
                      }

                      try {
                        screen.orientation.lock('landscape');
                      } catch (e) {
                        console.log('orientation landscape not allowed');
                      }
                      // }
                    }
                    console.log("jamming");
                    // props.onHide();
                    // props.setWTF(0);
                    props.setJamming(true);
                    props.setLevelNum(JAM_LEVEL);
                    props.gameModalForward();
                    //                    props.setShowGameModal(true);
                  }}
                  // variant="contained"
                  style={{
                    fontFamily: 'Orbitron',
                    // position: 'absolute', bottom: 33, left: 33,
                    backgroundColor: 'black', color: 'white', fontWeight: 'bold',
                    paddingLeft: '33px', paddingRight: '33px'
                  }}
                >
                  Jam</Button>

                {/* <Button
          onClick={() => {
            props.setShowGameModal(true);
          }}
          style={{
            fontFamily: 'Orbitron', 
            // position: 'absolute', bottom: 33, right: 33,
            backgroundColor: 'black', color: 'white', fontWeight: 'bold',
            paddingLeft: '33px', paddingRight: '33px'
          }}
        >
          Play Game!</Button> */}
                <br />
                <br />

                <Button
                  onClick={() => {
                    props.toolModalForward();
                    // props.onHide();
                    props.setWTF(0);
                  }}
                  style={{
                    fontFamily: 'Orbitron',
                    //  position: 'absolute', bottom: 33, right: 33,
                    backgroundColor: 'black', color: 'white', fontWeight: 'bold',
                    paddingLeft: '33px', paddingRight: '33px'
                  }}
                >
                  use tool</Button>


                <br />
                <br />
                <Button
                  onClick={() => {
                    props.setShowAboutModal(true);
                    // window.open('https://buymeacoffee.com/lemuel', '_blank');
                  }}
                  style={{
                    fontFamily: 'Orbitron',
                    fontSize: 'small',
                    //  position: 'absolute', bottom: 33, right: 33,
                    backgroundColor: 'black', color: 'white',
                    paddingLeft: '11px', paddingRight: '11px'
                  }}
                >
                  About</Button>


              </center>
            </Col>
            <Col>
              <center>
                <br />
                <img
                  style={{
                    borderRadius: '50%',
                    border: 'blueviolet solid',
                    // position: 'absolute', right: 33 
                  }}
                  src={"/ludwig.gif"} width='300' />
              </center>
            </Col>
          </Row>
          {/* <p style={{ float: 'right', fontSize: 'small', fontFamily: 'Orbitron', color: 'white' }}>
            Created using <a href='https://magenta.tensorflow.org/pianogenie'> Google Magenta</a></p> */}
          {/* <p>Created using <a href='https://magenta.tensorflow.org/pianogenie'>Magenta's Piano Genie</a></p> */}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeModal;
