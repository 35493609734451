import React, { useState } from "react";
import { Card, ButtonGroup, Button, Container, Row, Col } from "react-bootstrap";
import { FaPlay,FaPause,FaStop } from 'react-icons/fa';
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import { IconButton } from "@mui/material";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { wtfKeys } from "./Onboarding";

const PlayerComponent = (props) => {
  const [volume, setVolume] = useState(0);

  const setVolumeSlider = (event) => {
    console.log("volume set: " + event.target.value);
    setVolume(event.target.value);
    props.setPlayerVolume(event.target.value);
  };

  return (
    <Container
        id='background_music'
        style={(props.wtf < wtfKeys.indexOf("BACKGROUND_MUSIC")) ? { display: 'none' } : { display: 'block'}}
    >
<Row style={{display:'inline-flex', border: '2px solid #1b22e4',  background: 'antiquewhite'}}>
      <Col>
        <Dropdown 
            style={{backgroundColor: "beige"}}
          placeholder="Backing Track"
          options={props.mp3s}
          value={props.mp3}
          onChange={(value) => {
            console.log("change!", value);
            props.setmp3(value.value);
          }}
          onSelect={(value) => console.log("selected!", value)} 
          onOpen={() => console.log("open!")}
        />
      </Col>
      <Col>
        <ButtonGroup>
          
          <IconButton 
            disabled={!props.active}
            onClick={props.play}>
            <PlayCircleIcon />
          </IconButton>
          <IconButton onClick={props.stop}>
            <StopCircleIcon />
          </IconButton>
          <IconButton onClick={props.pause}>
            <PauseCircleIcon />
          </IconButton>
        </ButtonGroup>
      </Col>
      <Col style={{paddingTop:'11px'}}>
            <input
              value={props.mp3Vol}
              type="range"
              onChange={setVolumeSlider}
              min="-11"
              max="11"
              step="0.25"
            ></input>
      </Col>
    </Row>
    </Container>
  );
};

export default PlayerComponent;
