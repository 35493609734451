import React, { useState } from "react";
import { Modal, Container, Button, Row, Col } from "react-bootstrap";
import { Dropdown, Selection } from "react-dropdown-now";
import { FaClone } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./css/SocialModal.css";

const SocialModal = (props) => {
  function myFunction() {
    console.log('copied');
    // Copy the text 
    navigator.clipboard.writeText(props.url);
    alert('link copied');

    $("#message").text("link copied");
  }

  function onHide() {
    console.log("CLOSING modal");
    props.setShowSocial(false);
  }

  return (
    <Modal
      // fullscreen={true}
      {...props}
      show={props.showSocial}
      onHide={onHide}
      // aria-labelledby="contained-social-modal-title-vcenter"
      style={{ zIndex: '222222' }}
      id='socialmodal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <center>Share your creation</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid"
        style={{ padding: '33px' }}>
        <div className="icon-container1 d-flex">
        <div style={{width: '200px', textAlign: 'center', fontSize: 'large'}}>
          {/* <div className="smd"> */}
          <TwitterShareButton
              url={props.url}
              hashtag={"#music #AI"}
              description={"my Ludwig van Ai creation"}
            >
            <FaTwitter style={{ color: '#4c6ef5', backgroundColor: 'aliceblue' }} />
            </TwitterShareButton>
            <p>Twitter</p>
          </div>
          <div style={{width: '200px', textAlign: 'center', fontSize: 'large'}}>
            <FacebookShareButton
              url={props.url}
              hashtag={"#music #AI"}
              description={"my Ludwig van Ai creation"}
            >
              <FaFacebook style={{ color: '#3b5998', backgroundColor: '#eceff5' }} />
            </FacebookShareButton>
            <p>Facebook</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <label style={{ fontWeight: "600" }}>
          Page Link &nbsp;&nbsp;<span id='message'></span>
        </label>
        <br />
        {/* <Row> */}
        <div>
          {props.url}
          <button onClick={myFunction} style={{ float: 'right', background: 'white' }}><FaClone /></button>
          {/* </Button> */}
        </div>

      </Modal.Footer>
    </Modal>
  );
};

export default SocialModal;
