import { levels } from "./GameJson";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Selection } from "react-dropdown-now";
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import "../styles.css";
import KeyOf from "./KeyOf";
import Recording from "./Recording";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PianoKeyScaleModal from './PianoKeyScaleModal';
import { TrySharp } from '@mui/icons-material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { FormControlLabel, Switch } from "@mui/material";
import { IconButton } from "@mui/material";
import { Modal, Card, ButtonGroup, Button, Container, Row, Col } from "react-bootstrap";
// import PianoKeyScaleModal2 from './PianoKeyScaleModal2';
import DrumsComponent from "./DrumsComponent";

var instrumentKeyMap = {
  "0-1": 's',
  "00": 'd',
  "01": 'f',
  '1-1': 'j',
  '10': 'k',
  '11': 'l'
}

var keys = [
  "A",
  "A#",
  "B",
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#"
];
var scales = ["none", "major", "minor"];

function simulateKey(key, type, modifiers) {
  var evtName = (typeof (type) === "string") ? "key" + type : "keydown";
  var modifier = (typeof (modifiers) === "object") ? modifier : {};

  var event = document.createEvent("HTMLEvents");
  event.initEvent(evtName, true, false);
  event.key = key;

  for (var i in modifiers) {
    event[i] = modifiers[i];
  }

  document.dispatchEvent(event);
}

const JAM_LEVEL = levels.length - 2;

const GameModal = (props) => {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const keyofprops = Object.assign({}, props);
  var show_game = false;


  const [volume, setVolume] = useState(0);

  const setVolumeSlider = (event) => {
    console.log("volume set: " + event.target.value);
    setVolume(event.target.value);
    props.setPlayerVolume(event.target.value);
  };


  useEffect(() => {
    props.setShowGameModal(true);
  }, [])

  const [showStory, setShowStory] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [simpleQuant, setSimpleQuant] = useState('Quant: disabled');
  const [showPianoKeyScale, setShowPianoKeyScale] = useState(false);
  const [scaleRatio, setScaleRatio] = useState(1);
  const [percentage, setPercentage] = useState(23);
  const [nextReady, setNextReady] = useState(false);
  const [stopDrums, setStopDrums] = useState(false);


  const lastNotePlayedTime = useRef(Date.now());
  const mappings = useRef({});
  const notesPlayed = useRef(0);

  useEffect(() => {
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [keyDown]);


  // instrumentKeyMap[note.instrument.toString() + note.pitch.toString()])

  // show on canvas
  function keyDown(key) {
    if (key.repeat && !props.allowRepeat) {
      return;
    }
    activate(key.key);
  }

  function activate(key) {
    var element = mappings.current[key];
    if (element) {
      element.canvasImage.setSrc(element.imageClick);
      editor?.canvas.renderAll();
      setTimeout(() => {
        element.canvasImage.setSrc(element.image);
        editor?.canvas.renderAll();
      }, 222);
    }
  }


  useEffect(() => {
    console.log("FABRIC,props.levelNum");
    try {
      if (!showStory) {
        updateCanvas();
        console.log(fabric);
        console.log('props.levelNum:' + props.levelNum);
      }
    } catch (exception) {
      console.log(exception);
    }

    setSimpleQuant("Quant: disabled");

  }, [fabric, props.levelNum])

  useEffect(() => {
    mappings.current = [];
    setStopDrums(true);
    props.stop();
    props.setPlayTrioDrums(false);
    notesPlayed.current = 0;
    setNextReady(false);
    editor?.deleteAll();
    addToCanvas();
    updateCanvas();
    if (props.jamming) {
      console.log("JAMMING PLAY LEVEL");
      playLevel();
      props.setJamming(false);
      return;
    }
    if (props.params.playback) {
      playLevel();
      return;
    }
    if (props.params.s) {
      if (props.params.s == 'p') {
        playLevel();
      } else if (props.params.s == 's') {
        setShowStory(true);
      }
      return;
    }
    if (levels[props.levelNum].video) {
      setShowVideo(true);
      setTimeout(() => document.querySelector('video').play(), 555);
    } else if (levels[props.levelNum].story) {
      setShowStory(true);
    }

    //  load video next level
    try {
      if (levels[props.levelNum + 1].video) {
        preloadVideo(levels[props.levelNum + 1].video)
      }
    } catch (exception) {
      console.log(exception);
    }
    // TODO load images next level

  }, [props.levelNum])

  async function preloadVideo(src) {
    const res = await fetch(src);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  function handleWindowResize() {
    updateCanvas();
  }
  window.addEventListener('resize', handleWindowResize);

  function addToCanvas() {
    var level = levels[props.levelNum];
    console.log("level: " + props.levelNum);
    fabric.Image.fromURL(level.backgroundImage, function (oImg) {
      oImg.scale(720 / level.backgroundImageWidth);
      editor?.canvas.add(oImg);
      // var strokeWidth = 0;
      // if (props.params.dev) {
      //   strokeWidth = 3;
      // }

      // var video1El = document.getElementById('video1');

      // var video1 = fabric.Image.fromURL('/lud.mp4',  function (i) {
      //   i.left = 22;
      //   i.top = 44;

      //   i.originX= 'center',
      //   i.originY= 'center',
      //   i.objectCaching= false,
      //   editor?.canvas.add(i);
      //   i.getElement().play();
      //   console.log(i);
      // });

      level.elements.forEach(element => {
        var im = fabric.Image.fromURL(element.image, function (i) {
          if (props.params.dev != 1) {
            i.selectable = false;
            i.hoverCursor = 'pointer';
          } else {
            i.selectable = true;
          }

          if (element.scale) {
            i.scale(element.scale);
          } else {
            i.scale(720 / level.backgroundImageWidth);
          }
          i.left = element.left;
          i.top = element.top;
          editor?.canvas.add(i);

          element.canvasImage = i;
          mappings.current[element.letter] = element;
          mappings.current[element.letter.toLowerCase()] = element;

          // console.log("ADDING EVENT");
          // document.addEventListener("keydown", (event) => {console.log("EVENT");console.log(event)});

          if (props.params.dev == 1) {
            i.on('moving', function (e) {
              console.log(e);
              if (props.params.dev == 1) {
                document.querySelector('#x').textContent = e.transform.lastX - e.transform.offsetX;
                // $("#x").text(e.transform.lastX);
                $("#y").text(e.transform.lastY - e.transform.offsetY);
                $("#scale").text(e.transform.scaleX);
              }
            });
          } else {
            i.on('mousedown', function (e) {
              console.log("mousedown:" + element.mousedown);
              // playFromGame(0,1);
              simulateKey(element.letter);
              i.setSrc(element.imageClick);
              editor?.canvas.renderAll();
              setTimeout(() => {
                i.setSrc(element.image);
                editor?.canvas.renderAll();
              }, 222);
            });
          }
        });

        // var r = new fabric.Rect({
        //   selectable: false,
        //   strokeWidth: strokeWidth,
        //   // strokeDashArray: [9, 9],
        //   stroke: element.strokeColor,
        //   hoverCursor: 'pointer',
        //   top: element.top,
        //   left: element.left,
        //   height: element.height,
        //   width: element.width,
        //   borderOpacity: 0.8,
        //   // opacity: 0.2,
        //   fill: 'rgba(0,0,0,0)'
        // })
        // editor?.canvas.add(r);
        // var topText = (element.top + element.top  + element.height )/2;
        // var topText = element.top - 38;
        if (element.letterPosition == 'bottom') {
          var topText = element.top + element.height + 4;
          var leftText = (element.left + element.left + element.width) / 2 - 18;
        } else {
          var topText = element.top - 30;
          var leftText = (element.left + element.left + element.width) / 2 - 18;
        }
        var t = new fabric.Text(" " + element.letter + " ",
          {
            top: topText,
            selectable: false,
            hoverCursor: 'pointer',
            hoverCursor: 'pointer',
            left: leftText,
            fontSize: 25,
            fill: 'black',
            backgroundColor: element.strokeColor
          });
        editor?.canvas.add(t);

        if (element.hasOwnProperty("text")) {
          var text = new fabric.Text(" " + element.text + " ",
            {
              top: element.top - 50,
              selectable: false,
              hoverCursor: 'pointer',
              hoverCursor: 'pointer',
              left: element.left + 4,
              fontSize: 12,
              fill: 'black',
              backgroundColor: 'beige'
            });
          editor?.canvas.add(text);
          editor?.canvas.bringToFront(text);
        }

        // r.on('mousedown', function (e) {
        //   console.log("mousedown:" + element.mousedown);
        //   // playFromGame(0,1);
        //   simulateKey(element.letter);
        //   // eval(element.mousedown);
        //   // eval("()=> {props.play(0)}");
        //   var strokeWidth = 0;
        //   if (props.params.dev) {
        //     strokeWidth = 5;
        //   }

        //   e.target.strokeWidth = strokeWidth;
        //   e.target.stroke = 'black';
        //   oImg.scale(720 / level.backgroundImageWidth);
        //   editor?.canvas.renderAll();
        //   setTimeout(() => {
        //     e.target.strokeWidth = strokeWidth;
        //     e.target.stroke = element.strokeColor;
        //     oImg.scale(720 / level.backgroundImageWidth);
        //     editor?.canvas.renderAll();
        //   }, 222);

        //   // e.target should be the circle
        //   console.log(e.target);
        // });
        // //   r.on('mouseup',  function(e) {
        // //     e.target.strokeWidth= 2;        
        // //     // e.target should be the circle
        // //     console.log(e.target);
        // //     editor?.canvas.renderAll();
        // // });        
        // r.on('object:over', function (e) {
        //   e.target.cornerStrokeColor = 'yellow';
        //   e.target.strokeWidth = 11;
        //   alert('hover');
        //   // e.target should be the circle
        //   console.log(e.target);
        // });
      })

      // var video1 = new fabric.Image.fromURL('/lud.mp4', {
      //   left: 200,
      //   top: 300,
      //   width:222,
      //   heigh:222,
      //   angle: -15,
      //   originX: 'center',
      //   originY: 'center',
      //   objectCaching: false,
      // });
      // editor?.canvas.add(video1);
      // video1.getElement().play();

      var url = '/lud.mp4';

      // var videoE = document.createElement('video');
      // videoE.width = 555;
      // videoE.height = 1111;
      // videoE.muted = true;
      // videoE.crossOrigin = "anonymous";
      // var source = document.createElement('source');
      // source.src = url;
      // source.type = 'video/mp4';
      // videoE.appendChild(source);
      //   return videoE;
      // var videoE = getVideoElement(url_mp4);
      // var v = document.getElementById('v');
      // var video1 = new fabric.Image(v, {
      //   left: 100,
      //   top: 100,
      //   angle: -15,
      //   originX: 'center',
      //   originY: 'center',
      //   objectCaching: false,
      // });
      // console.log('video');
      // editor?.canvas.add(video1);
      // editor?.canvas.bringToFront(video1);
      // video1.getElement().play();


      // fabric.util.requestAnimFrame(function render() {
      //   editor?.canvas.renderAll();
      //   fabric.util.requestAnimFrame(render);
      // });
      // var fab_video = new fabric.Image(v, { left: 0, top: 0, width: 222, height: 222, scaleToHeight: .5 });
      // editor?.canvas.add(v);
      // v.play();
      // fabric.util.requestAnimFrame(function render() {
      //   canvas.renderAll();
      //   fabric.util.requestAnimFrame(render);
      // });

      // var fab_video = new fabric.Image.fromURL('/lud.mp4', { left: 0, top: 0, width: 444, height: 555, scaleToHeight: .5 });



      // var fab_video = new fabric.Image.fromURL('/lud.mp4', function(i) {
      //   // { left: 0, top: 0, width: 444, height: 555, scaleToHeight: .5 });
      //   i.left= 0;
      //   i.top= 0;
      //   i.width= 444;
      //   i.height= 555;
      //   i.scaleToHeight= .5;
      // i.set('video_src', url);
      // i.bringToFront();
      // i.scale(.5);
      // editor?.canvas.add(i);
      // i.getElement().play();
      // });



      // fab_video.bringToFront();
      // fab_video.on('mousedown', function (e) {
      //   console.log(e);
      //   fab_video.getElement().play();

      //   // POST request using fetch with set headers
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': 'Bearer my-token',
      //       'Access-Control-Allow-Origin': 'https://ludwigvanai.com',
      //       'My-Custom-Header': 'foobar'
      //     },
      //     body: JSON.stringify({ title: 'React POST Request Example' })
      //   };
      //   fetch('https://ludwigvanai.com/r.php', requestOptions)
      //     .then(response => response.json())
      //     .then(data => console.log({ postId: data.id }));
      // })





    }, { selectable: false });
  }

  function updateCanvas() {
    var level = levels[props.levelNum];
    console.log("level: " + props.levelNum);
    var element = level.elements[0];
    // if (level.preset != null) {
    //   props.setGamePreset(level.preset);
    // }

    //   fabric.Image.fromURL(element.image, function (oImg) {
    //     oImg.scale(1.05);
    //     editor?.canvas.add(oImg);
    //     console.log(editor?.canvas);
    //     oImg.on('mousedown', function(e) {
    //       eval(element.mousedown);       
    //       // e.target should be the circle
    //       console.log(e.target);
    //   });
    //   }, {top: element.top, selectable: false, left: element.left, strokeWidth: 5, stroke: 'black', borderColor: 'red', hasBorders: 'true', borderScaleFactor: 3});
    // });
    // editor?.canvas.setHeight(768);
    // editor?.canvas.setWidth(400);
    var scaleRatioW = window.innerWidth / 922;
    var scaleRatioH = window.innerHeight / 480;
    var sr = Math.min(scaleRatioH, scaleRatioW);
    if (sr < 0.8) {
      sr = 0.8;
    }
    setScaleRatio(sr);
    console.log("scaleratio: " + scaleRatio);
    var perc = Math.floor((window.innerWidth - (sr * 720)) / window.innerWidth * 100);
    setPercentage(perc);
    console.log("percentage: " + perc);
    editor?.canvas.setZoom(1) // Must set zoom = 1 before panning
    var panx = (720 * sr - 720) / 2;
    var pany = (480 * sr - 480) / 2;
    console.log(panx);
    console.log(pany);
    // console.log(window.innerWidth );
    console.log(editor);
    editor?.canvas.absolutePan(new fabric.Point(-panx, -pany));
    editor?.canvas.setDimensions({ width: 720 * sr, height: 480 * sr });
    editor?.canvas.zoomToPoint(new fabric.Point(editor?.canvas.width / 2, editor?.canvas.height / 2), sr)

    // var zoom = Math.min(canvas.height/WB_HEIGHT, canvas.width/WB_WIDTH) * 0.9
    // canvas.absolutePan(new fabric.Point(panX,panY))
    editor?.canvas.renderAll();
    // fabric.util.requestAnimFrame(function render() {
    //   editor?.canvas.renderAll();
    //   fabric.util.requestAnimFrame(render);
    // });

    // alert(720 * scaleRatio);
  }

  const setDropDownQuant = (event) => {
    console.log("quant: " + event.value);
    // setInstrumentName(event.value);
    // props.instrument.setInstrument(instruments[event.value]);
    setSimpleQuant(event.value);
    switch (event.value) {
      case 'Quant: disabled':
        props.setQuant('disabled');
        break;
      case 'fast':
        props.setBpm('120');
        props.setQuant('1/4');
        break;
      case 'medium':
        props.setBpm('60');
        props.setQuant('1/4');
        break;
      case 'slow':
        console.log('slow');
        props.setBpm('80');
        props.setQuant('1');
        break;
    }
  };

  function changeTrioDrums(event) {
    console.log(event);
    props.setPlayTrioDrums(event.target.checked);
  }

  const setDropDownInstrumentName = (event) => {
    console.log("instr name: " + event.value);
    // setInstrumentName(event.value);
    // props.instrument.setInstrument(instruments[event.value]);
    props.instruments[0].setInstrumentName(event.value);
  };
  const setDropDownInstrumentName2 = (event) => {
    console.log("instr name: " + event.value);
    // setInstrumentName(event.value);
    // props.instrument.setInstrument(instruments[event.value]);
    props.instruments[1].setInstrumentName(event.value);
  };

  function endGame() {
    props.setGamePreset('GameBase');
    setShowStory(false);
    props.onHide();
    // document.getElementById("gamemodal").parent.style.zIndex = "-11";
    console.log('THE END');
    document.exitFullscreen();
    props.setShowWelcomeModal(true);
  }

  function storyClickHandler(e) {
    const el = e.target.closest("button");
    if (el && e.currentTarget.contains(el)) {
      if (el.id == 'play_level') {
        playLevel();
      }
      if (el.id == 'end_button') {
        endGame();
      }
      if (el.id == 'spks') {
        setShowPianoKeyScale(true);
        console.log(showPianoKeyScale)
      }
      // alert("FOUND");
      // ...do your state change...
    }
  }

  function playLevel() {
    console.log('Play level: ' + props.levelNum);
    if (levels[props.levelNum].preset != null) {
      props.setGamePreset(levels[props.levelNum].preset);
    }
    editor?.canvas._objects.forEach(o => { editor?.canvas.remove(o); })
    addToCanvas();
    updateCanvas();
    setShowVideo(false);
    setShowStory(false);
  }
  function previousLevel() {
    props.setLevelNum(props.levelNum - 1);
  }

  function done() {
    document.exitFullscreen();
    props.stop();
    props.setJamming(false);
    props.setShowWelcomeModal(true);
    props.gameModalBack();
    setStopDrums(true);
  }

  function nextLevel() {

    props.setLevelNum(props.levelNum + 1);
  }

  function _handleKeyDown(key) {
    switch (key.key) {
      case '\'':
        setShowPianoKeyScale(!showPianoKeyScale);
        break;
    }
  }

  useEffect(() => {
    if (props.lastPlayedNote.playback) {
      activate(instrumentKeyMap[props.lastPlayedNote.instrumentNumber.toString() + props.lastPlayedNote.pitch.toString()]);
    }
    lastNotePlayedTime.current = Date.now();
    notesPlayed.current = notesPlayed.current + 1;
    if (notesPlayed.current > 11 && !props.params.playback) {
      setTimeout(() => setNextReadyIfWasLastNote(), 2555);
    }
  }, [props.lastPlayedNote]);

  function setNextReadyIfWasLastNote() {
    if (Date.now() - lastNotePlayedTime.current > 2222) {
      setNextReady(true);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);
    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
    };
  }, [_handleKeyDown]);

  function handleClosePKS() {
    setShowPianoKeyScale(false);
    playLevel();
    // nextLevel();
  }

  // const enter = () => {
  //   console.log("ENTERRRING - jamming:");// + props.jamming);
  //   // if (!editor){
  //   //   selectedObjects, editor, onReady  = useFabricJSEditor();
  //   // }
  //   console.log(editor);
  //   console.log(onReady);

  //   if (props.jamming) {
  //     // document.querySelector("body").requestFullscreen();
  //     props.setLevelNum(JAM_LEVEL);
  //     console.log('level set jamming');
  //     // updateCanvas();
  //     // playLevel();
  //   } else {
  //     props.setLevelNum(0);
  //     // playLevel();
  //     // updateCanvas();
  //   }
  // }

  function doneVideo() {
    setShowStory(true);
    setShowVideo(false);
  }

  return (
    <div>
      <Modal
        fullscreen={true}
        // onHide={handleClosePKS}
        show={showPianoKeyScale}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: '22222', backgroundColor: "burlywood" }}
      >
        <Modal.Body className="show-grid"
          style={{ fontFamily: 'sans-serif', zIndex: '22222', backgroundColor: "burlywood" }}
        >
          <PianoKeyScaleModal
            {...props}
          />
          <center>
            <button style={{ fontSize: "x-large" }} onClick={handleClosePKS}>Cool, got it</button>
          </center>
        </Modal.Body>
      </Modal>

      {/* <div id='gamemodaldiv'
            style={{ zIndex: '4444' }}
            > */}
      <Modal
        id='gamemodal'
        fullscreen={true}
        {...props}
        // onEnter={enter}
        // onEnter={() => enter()}
        className='storymodal'
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: '-1', padding: 0 }}
      >
        {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <center>Ludwig Von A.I.</center>
        </Modal.Title>
      </Modal.Header> */}
        <Modal.Body>
          {/* <video id='v' style={{ position: "fixed", left: '100px', display: 'none' }} >
            <source src="/lud.mp4" />
          </video> */}
          <table style={{ backgroundColor: 'beige' }}>
            <tbody>
              <tr>
                <th style={{ verticalAlign: 'top', width: percentage + '%', position: 'relative' }}>
                  <div
                    style={{ fontFamily: 'sans-serif', fontSize: scaleRatio > 1.4 ? 'x-large' : scaleRatio > 1 ? 'large' : 'small' }}
                  >
                    <div id='controls' style={{ marginTop: 11, marginBottom: 22 }}>
                      {console.log(props.levelNum)}
                      {console.log(levels)}
                      {levels && levels[props.levelNum] && levels[props.levelNum].keyOf &&
                        <center style={{ display: 'flex', justifyContent: 'center' }}>
                          <Dropdown
                            options={keys}
                            value={props.keyOf}
                            onChange={(value) => {
                              console.log("change!", value);
                              props.setKeyOf(value.value);
                            }}
                          />

                          <Dropdown
                            options={scales}
                            value={props.scale}
                            onChange={(value) => {
                              console.log("change!", value);
                              props.setScale(value.value);
                            }}
                          />
                        </center>
                      }

                      {(levels[props.levelNum].hasOwnProperty('player') && levels[props.levelNum].player) &&
                        <div style={{ margin: "10px", border: "purple solid 2px" }}>
                          <Dropdown
                            style={{ backgroundColor: "beige" }}
                            placeholder="Backing Track"
                            options={props.mp3s}
                            value={props.mp3}
                            onChange={(value) => {
                              console.log("change!", value);
                              props.setmp3(value.value);
                            }}
                            onSelect={(value) => console.log("selected!", value)}
                            onOpen={() => console.log("open!")}
                          />
                          <ButtonGroup>
                            <IconButton
                              disabled={!props.active || props.playerStatus == 'PLAYING'}
                              onClick={props.play}>
                              <PlayCircleIcon />
                            </IconButton>
                            <IconButton onClick={props.stop}
                              disabled={!props.active || props.playerStatus != 'PLAYING'}>
                              <StopCircleIcon />
                            </IconButton>
                            <IconButton onClick={props.pause}
                              disabled={!props.active || props.playerStatus != 'PLAYING'}>
                              <PauseCircleIcon />
                            </IconButton>
                          </ButtonGroup>
                          <input
                            value={props.mp3Vol}
                            type="range"
                            onChange={setVolumeSlider}
                            min="-11"
                            max="11"
                            step="0.25"
                          ></input>


                        </div>
                      }

                      {(levels[props.levelNum].hasOwnProperty('quant') && levels[props.levelNum].quant) &&
                        <div style={{ margin: "10px" }}>
                          <Dropdown
                            options={['Quant: disabled', 'slow', 'medium', 'fast']}
                            value={simpleQuant}
                            onChange={(value) => {
                              setDropDownQuant(value);
                              console.log(value);
                            }}
                          />
                        </div>
                      }

                      {levels[props.levelNum].instrument2 &&
                        <div style={{ margin: "10px", border: "red solid 4px" }}>
                          <Dropdown
                            options={['piano', 'harp', "Kalimba", 'guitar-acoustic', "duosynth", 'guitar-electric', 'flute', 'trumpet']}
                            value={props.instruments[1].instrumentName}
                            onChange={(value) => {
                              setDropDownInstrumentName2(value);
                              console.log(value);
                            }}
                          />
                        </div>
                      }

                      {levels[props.levelNum].instrument &&
                        <div style={{ margin: "10px", border: "green solid 4px" }}>
                          <Dropdown
                            options={['piano', 'bass-electric', 'xylophone', 'harp', "Kalimba", 'organ', 'synthtom', 'violin', 'guitar-nylon']}
                            value={props.instruments[0].instrumentName}
                            onChange={(value) => {
                              setDropDownInstrumentName(value);
                              console.log(value);
                            }}
                          />
                        </div>
                      }
                      {levels[props.levelNum].recording &&
                        <Recording
                          setmp3={props.setmp3}
                          play={props.playMp3}
                          stop={props.stop}
                          pause={props.pause}
                          mp3Vol={props.mp3Vol}
                          setPlayerVolume={props.setPlayerVolume}

                          lastAction={props.lastAction}
                          playbackNote={props.playbackNote}
                          loadedRecording={props.loadedRecording}
                          instruments={props.instruments}
                          levelNum={props.levelNum}
                          record={props.record}
                          setRecord={props.setRecord}
                          small={scaleRatio < 1}
                          playBack={props.playBack}
                          lastPlayedInstrument={props.lastPlayedInstrument}
                          lastPlayedAny={props.lastPlayedAny}
                          lastPlayedNote={props.lastPlayedNote}
                          wtf={22}
                        />
                      }
                      {levels[props.levelNum].drums &&
                        <DrumsComponent
                          stopDrums={stopDrums}
                          setStopDrums={setStopDrums}
                          setPlayTrioDrums={props.setPlayTrioDrums}
                        />
                      }
                    </div>
                    <div style={{ margin: 11 }} id='instructions' dangerouslySetInnerHTML={levels[props.levelNum].instructions} />
                    <br />
                    {props.params.dev == 1 &&
                      <div>
                        x:<span id='x'>/</span><br />
                        y: <span id='y'>a</span><br />
                        scale: <span id='scale'>s</span>
                      </div>
                    }
                    <div style={{ position: 'absolute', bottom: 11, fontSize: scaleRatio > 1.4 ? 'x-large' : scaleRatio > 1 ? 'large' : 'small' }}>
                      <div>
                        {props.levelNum > 0 && props.levelNum != JAM_LEVEL && props.params.dev == 1 &&
                          <button id='prev' onClick={() => previousLevel()}>{'<<'} Prev</button>
                        }
                        {props.levelNum < levels.length && props.levelNum != JAM_LEVEL && (nextReady || props.params.dev == 1) &&
                          <button style={{ border: 'solid 4px magenta', float: 'left' }} id='next' onClick={() => nextLevel()}>Next {'>>'}</button>
                        }
                        {props.levelNum == JAM_LEVEL &&
                          <button style={{ float: 'left' }} id='next' onClick={() => done()}>Done</button>
                        }
                        <span style={{ float: 'right' }} id='played'>{props.lastPlayedAny}</span>
                      </div>
                    </div>
                  </div>
                </th>
                <th style={{ width: '77%' }}>
                  <div style={{ width: '100%', height: '150%' }}>
                    <FabricJSCanvas
                      className="sample-canvas"
                      onReady={onReady}
                      style={{ width: '80%', height: '150%' }}
                    />
                  </div>

                </th>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {/* </div> */}

      <Modal
        // onEntering={() => enter()}
        className="storymodal"
        fullscreen={true}
        {...props}
        show={showStory && props.show}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: '2223' }}
      >
        <Modal.Body style={{ backgroundColor: 'beige' }}>
          <div style={{
            fontFamily: 'sans-serif', fontSize: 'xx-large', fontWeight: 'bold',
            margin: 22, textAlign: 'center', lineHeight: 2,
            paddingLeft: (props.levelNum == JAM_LEVEL) ? '0' : '20%',
            paddingRight: (props.levelNum == JAM_LEVEL) ? '0' : '20%',

            // position: 'absolute',
            // top: '50%', left: '50%',
            // transform: 'translate(-50%, -50%)'

            // position: 'absolute', 
            // left: 0, 
            // right: '0px', 
            // top: 0, 
            // bottom: 0,
            // margin: 'auto',
            // height: '222px'
            // position: 'absolute', left: '50%', top: '50%',
            // transform: 'translate(-50%, -50%)'
          }} >
            <div onClick={storyClickHandler} dangerouslySetInnerHTML={levels[props.levelNum].story} />
            {/* <br />
            <center>
              <Button id='playLevel' 
                style={{ color: 'black', backgroundColor: 'green', margin: '2px', width: '95px' }} 
                onClick={() => playLevel()}>Play!</Button>
            </center> */}
            {/* </div> */}
            {/* </center> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        // onEntering={() => enter()}
        className="videomodal"
        fullscreen={true}
        {...props}
        show={showVideo && props.show}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: '2223' }}
      >
        <Modal.Body style={{ backgroundColor: 'beige' }}>
          <div style={{
            fontFamily: 'sans-serif', fontSize: 'xx-large', fontWeight: 'bold',
            margin: 22, textAlign: 'center', lineHeight: 2,

          }} >
            <video autoplay width="95%" controls onEnded={() => { doneVideo() }}>
              <source src={levels[props.levelNum].video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <br />
            <center><Button onClick={doneVideo} id='showstory' style={{ color: 'black', backgroundColor: 'green', margin: '2px', width: '95px' }}>Next</Button></center>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export { GameModal, JAM_LEVEL };