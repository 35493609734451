import React, { useState } from "react";
import { Card, ButtonGroup, Button, Container, Row, Col } from "react-bootstrap";
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import PlayerComponent from "./PlayerComponent";
import Presets from "./Presets";
import { PencilSquare } from 'react-bootstrap-icons';
import ReactTooltip from "react-tooltip";
import { wtfKeys } from "./Onboarding";
import { Tooltip, FormControlLabel, Switch } from "@mui/material";

const MainPageInteract = (props) => {
  function changeTrioBass(event) {
    props.setPlayTrioBass(event.target.checked);
  }

  function changeTrioDrums(event) {
    console.log(event);
    props.setPlayTrioDrums(event.target.checked);
  }

  const setDrumVolumeSlider = (event) => {
    console.log("volume set: " + event.target.value);
    props.setDrumVolume(event.target.value);
  };

  return (
    <>
      <Presets
        // style={(props.wtf < wtfKeys.indexOf("PRESETS")) ? { display: 'none' } : { marginRight: "11px", paddingBottom: "11px" }}
        {...props}
      ></Presets>
      <PlayerComponent
        {...props}
      ></PlayerComponent>

    <div
        id='ai_drums'
        style={(props.wtf < wtfKeys.indexOf("AI_DRUMS")) ? { display: 'none' } :
    { marginRight: "11px",
        marginTop: '5px',
        border: '#e80962 solid 2px', 
        background: 'beige',
        display: 'inline-block',
        paddingLeft: '11px',
        paddingRight: '11px'
    }}>
      <Tooltip title="Once you start playing for a bit, the AI will maybe add what it thinks makes sense.">
        <FormControlLabel

          control={
            <Switch
              checked={props.playTrioDrums}
              onChange={changeTrioDrums}
            />}
          label="AI Drums"
        />
      </Tooltip>
            vol:&nbsp;
        <input

          value={props.drumVolume}
          type="range"
          onChange={setDrumVolumeSlider}
          min="0"
          max="100"
          step="1"
        ></input>
    </div>
    <div
        id='ai_bass'
        style={(props.wtf < wtfKeys.indexOf("AI_BASS")) ? { display: 'none' } :
    { marginRight: "11px",
        marginTop: '5px',
        border: '#e80962 solid 2px', 
        background: 'beige',
        display: 'inline-block',
        paddingLeft: '11px',
        paddingRight: '11px'
    }}>
      <Tooltip title="Once you start playing for a bit, the AI will maybe add what it thinks makes sense.">
        <FormControlLabel
          control={
            <Switch
              checked={props.playTrioBass}
              onChange={changeTrioBass}
            />}
            disabled={!props.playTrioDrums}
          label="AI Bass"
        />
      </Tooltip>
    </div>
    </>
  );
};

export default MainPageInteract;
