import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import ReactTooltip from "react-tooltip";
import { TbQuestionMark } from 'react-icons/tb';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { wtfKeys } from "./Info";
import { Stack } from "@mui/system";


const Quant = (props) => {
  var bpms = [
    "60",
    "80",
    "100",
    "120",
    "140",
    "160",
    "180"
  ];

  var quants = [
    "disabled",
    "1/8",
    "1/4",
    "1/2",
    "1"
  ];

  return (
        <Stack spacing={1} direction="row" justifyContent="flex-end"> 
                  <FormControl variant="standard" sx={{ m: 1, margin: '0px',minWidth: 22, width: 122 }} >

              <InputLabel id="bpm-label">BPM</InputLabel>
              <Select
                autoWidth
                labelId="bpm-label"
                placeholder="Select a bpm"
                label='bpm'
                value={props.bpm}
                onChange={(value) => {
                  props.setBpm(value.target.value);
                }}
              >
                {
                  bpms.map((key) => {
                    return <MenuItem value={key}>{key}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

          <FormControl variant="standard" sx={{ m: 1, margin: '0px', minWidth: 22, width: 122 }} >
            <InputLabel id="quant-label">Quantization</InputLabel>
            <Select
              autoWidth
              labelId="quant-label"
              placeholder="Select a Quantizatio"
              value={props.quant}
              onChange={(value) => {
                console.log(props.quant);
                console.log("change!", value);
                props.setQuant(value.target.value);
              }}
            >
              {
                quants.map((key) => {
                  return <MenuItem value={key}>{key}</MenuItem>
                })
              }
            </Select>

          </FormControl>
    </Stack>
  );
};

export default Quant;
