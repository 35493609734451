import React, { useRef, useState, useEffect } from "react";
import { Transport, Player } from "tone";
import Sound from "../mp3s/lead.mp3";
import { Card, ButtonGroup, Button, Container, Row, Col } from "react-bootstrap";
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import { IconButton } from "@mui/material";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { wtfKeys } from "./Onboarding";

var player = new Player({
    url: Sound, //'/sounds/dream.mp3',
    loop: true,
    autostart: false,
    onload: () => {
        //   App.enable();
        // alert("-0");
    }
}).toDestination();


const DrumsComponent = (props) => {
    const [volume, setVolume] = useState(0);
    const [drumSound, setDrumSound] = useState('No Drums');
    const playerRef = useRef(player);

    useEffect(() => {
        if (props.stopDrums) {
            console.log('stopping drums');
            setDrumMp3("");
            setDrumSound("None");
            props.setStopDrums(false);
        }
    }, [props.stopDrums]);


    // const drumSounds = ['None', 'AI Drums', 'lead.mp3', 'dream.mp3', 'c'];
    const drumSounds = [
        { label: "Drums: None", value: "None" },
        { label: "AI Drums", value: "AI Drums" },
        { label: "883", value: "883dry.mp3" },
        { label: "ab", value: "ab.mp3" },
        { label: "abs353", value: "abs353.mp3" },
        { label: "abs", value: "abs497.mp3" },
        { label: "dd", value: "dd1763.mp3" },
        { label: "dtm", value: "dtm126003.mp3" },
        { label: "glitch", value: "dtmglitch130014.mp3" },
        { label: "fd", value: "fd106.mp3" }
    ];


    function setDrumMp3(mp3File, autostart = true) {
        // Tone.Transport.stop();
        playerRef.current.stop();
        if (!playerRef.current._wasDisposed) {
            playerRef.current.dispose();
        }
        console.log("setmp3: " + mp3File);
        if (mp3File == null) {
            set_mp3("");
            return;
        }
        // set_mp3(mp3File);
        // const file = "https://0xdy3z.csb.app/mp3s/" + mp3File;
        // const file = "/drums/" + mp3File;
        const file = "/drums/" + mp3File;

        // setLastAction({ action: "SET_MP3", data: mp3File, time: Date.now() })
        // setActivePlayButtons(false);
        try {
            playerRef.current = new Tone.Player({
                url: file,
                loop: true,
                autostart: autostart,
                onload: () => {
                    // enable();
                    // alert("-0");
                    console.log('starting audio:' + file);
                }
            }).toDestination();

        } catch (exception) {
            console.log(exception);
        }
    }


    const setVolumeSlider = (event) => {
        console.log("volume set: " + event.target.value);
        setVolume(event.target.value);
    };

    const setDrumSoundFromDropDown = (drumSelected) => {
        console.log("drum selected: " + drumSelected);
        if (drumSelected == "AI Drums") {
            setDrumMp3("");
            props.setPlayTrioDrums(true);
        } else {
            props.setPlayTrioDrums(false);
            if (drumSelected == 'None') {
                setDrumMp3("");
                // props.drumPlayerStop();
                return;
            } else {
                setDrumMp3(drumSelected);
            }
            //            props.drumPlayerStart(drumSelected);
        }
    };


    return (
        <Container
            id='background_music'
        >
            <Row style={{ display: 'inline-flex', border: '2px solid #1b22e4', background: 'antiquewhite' }}>
                <Col>
                    <Dropdown
                        style={{ backgroundColor: "beige" }}
                        placeholder="Drums"
                        options={drumSounds}
                        value={drumSound}
                        onChange={(value) => {
                            console.log("change!", value);
                            setDrumSoundFromDropDown(value.value);
                        }}
                        onSelect={(value) => console.log("selected!", value)}
                        onOpen={() => console.log("open!")}
                    />
                </Col>
                <Col style={{ paddingTop: '11px' }}>
                    <input
                        value={volume}
                        type="range"
                        onChange={setVolumeSlider}
                        min="-11"
                        max="11"
                        step="0.25"
                    ></input>
                </Col>
            </Row>
        </Container>
    );
};

export default DrumsComponent;
